import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import DropZone from "../../../../shared/DropDown";
import AutocompleteInfo from "../components/AutocompleteInfo";
import { useState } from "react";
import { theme } from "../../../../theme.ts";

export default function FirstStepMainInfo({
  imagesAddedAndRefresh,
  formValid,
  form,
  setForm,
  t,
}) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const [purposes, setPurposes] = useState<{ id: number; name: string }[]>(
    Array.from({ length: 11 }, (_, i) => ({
      id: i,
      name: t(`SMAsistant.Step1.purpose${i + 1}`),
    }))
  );

  const handleTalkingPointsChange = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      talkingPoints: value,
    });
  };

  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step1.purposeTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl sx={{ width: "100%" }}>
            <AutocompleteInfo
              postPurpose={form.postPurpose}
              purposes={purposes}
              form={form}
              setForm={setForm}
              setPurposes={setPurposes}
              t={t}
              color={"#9e9e9e"}
            />
          </FormControl>
          {!formValid && !form.postPurpose.length ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step1.talkingPointsTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            required
            multiline
            value={form.talkingPoints}
            onChange={handleTalkingPointsChange}
            rows={isXS ? 4 : 3}
            sx={{
              width: "100%",
              borderRadius: "8px",
              border: "solid 1px  #9e9e9e",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "transparent", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Border color when focused
                },
              },
            }}
            id="outlined-basic"
            placeholder={t("SMAsistant.Step1.talkingPlaceholder")}
            variant="outlined"
          />
          {!formValid && !form.talkingPoints ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: "flex",
        }}
        container
        mt={2}
        spacing={2}
      >
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step1.photosTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <DropZone
              form={form}
              setForm={setForm}
              title={t("SMAsistant.Step1.dropZoneTitle")}
              mid={t("SMAsistant.Step1.dropZoneOption")}
              cta={t("SMAsistant.Step1.dropZoneCTA")}
              limit={t("SMAsistant.Step1.dropZoneLimit")}
            />
          </Box>
          {imagesAddedAndRefresh ? (
            <Box mt={1}>
              <Alert severity="error">{t("SMAsistant.Step1.dropZoneRefresh")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
