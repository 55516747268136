import * as React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import backgroundBrand from "../assets/dashboard_background_brand.svg";

interface BrandCreateBannerProps {
  t: (key: string) => string;
  isXS: boolean;
}

const BrandCreateBanner = ({ t, isXS }: BrandCreateBannerProps) => {
  return (
    <Grid item xs={12} sx={{ width: "100%", marginLeft: "5px" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            border: "none",
            backgroundImage: `url(${backgroundBrand})`, // Background image
            backgroundSize: "cover", // Cover the entire card
            backgroundPosition: "center", // Center the image
            backgroundRepeat: "no-repeat", // Do not repeat the image
            borderRadius: "8px", // Add border radius for rounded corners
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item md={12} lg={8}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: (theme) => theme.palette.primary.contrastText,
                    width: "90%",
                  }}
                  variant="h6"
                  component="div"
                >
                  {t("resposiveDrawer.home.brandCreateTitle")}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                    width: isXS ? "100%" : "50%",
                  }}
                >
                  {t("resposiveDrawer.home.brandCreateDesc")}
                </Typography>
              </Grid>
              <Grid item md={12} lg={3}></Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default BrandCreateBanner;
