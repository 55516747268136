import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Container,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CarouselStyles.css"; // This file will hold the CSS for the dots and other styling
import ReactGA from "react-ga4";

export default function Pricing({ t }) {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const cards = [
    {
      title: t("layout.pricingSection.card1title"),
      price: t("layout.pricingSection.card1price"),
      period: t("layout.pricingSection.card1period"),
      features: t("layout.pricingSection.card1featuresList", {
        returnObjects: true,
      }),
      btnText: t("layout.pricingSection.card1btn"),
      link: "dashboard/home",
      disabled: false,
    },
    {
      title: t("layout.pricingSection.card2title"),
      price: t("layout.pricingSection.card2price"),
      features: t("layout.pricingSection.card2featuresList", {
        returnObjects: true,
      }),
      btnText: t("layout.pricingSection.card2btn"),
      disabled: true,
    },
  ];

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const renderCard = (card, index) => (
    <Grid item xs={12} sm={6} md={5} key={index} mb={isXs ? 4 : 2} mt={1}>
      <Card
        sx={{
          height: "100%",
          maxWidth: "85%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "16px",
          background: card.disabled
            ? "white"
            : (theme) => theme.palette.gradient.mainGradientReversed,
          boxShadow: card.disabled
            ? "0px 4px 20px rgba(0, 0, 0, 0.1)"
            : "0px 4px 20px #FF6663",
          margin: "0 auto",
          opacity: card.disabled ? 0.5 : 1,
          filter: card.disabled ? "grayscale(100%)" : "none",
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            padding: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              mb: 3,
              height: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                mb: 2,
                height: "64px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="h5"
              component="div"
            >
              {card.title}
            </Typography>
            <Typography
              sx={{ fontWeight: "700", mb: 1 }}
              variant={card.disabled ? "h3" : "h2"}
              component="div"
            >
              {card.price}
            </Typography>
            {!card.disabled && (
              <Typography variant="body1" color="black">
                {card.period}
              </Typography>
            )}
          </Box>
          <Divider
            sx={{
              width: "100%",
              mt: 3,
              mb: 1,
              borderBottomWidth: 1,
              bgcolor: card.disabled ? "#eee" : "black",
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <List
              sx={{
                paddingLeft: "20px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              {card.features.map((feature, index) => (
                <ListItem key={index} sx={{ marginBottom: 1, padding: 0 }}>
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                    <TaskAltIcon
                      color={card.disabled ? "disabled" : "primary"}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    sx={{ color: card.disabled ? "#b3b3b3" : "inherit" }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", padding: "16px" }}>
          {!card.disabled ? (
            <Link
              to={card.link}
              style={{ textDecorationColor: "transparent", width: "100%" }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  background: (theme) =>
                    theme.palette.figma.primary.yellow.fifth,
                  color: "#000",
                  textTransform: "none",
                  fontSize: "16px",
                  "&:hover": {
                    background: (theme) =>
                      theme.palette.figma.primary.yellow.fifth,
                  },
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "user_engagement",
                    action: "click",
                    label: "pricing_page_cta_btn",
                  });
                }}
              >
                {card.btnText}
              </Button>
            </Link>
          ) : (
            <Button
              variant="contained"
              disabled
              sx={{
                width: "100%",
                borderRadius: "10px",
                background: "#eee",
                color: "#b3b3b3",
                textTransform: "none",
                fontSize: "16px",
                cursor: "not-allowed",
              }}
            >
              {card.btnText}
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <Box
      mt={5}
      sx={{
        paddingBottom: "50px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container>
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            color: (theme) => theme.palette.figma.secondary.orange.second,
          }}
          mt={5}
          variant="h4"
          component="div"
        >
          {t("layout.pricingSection.title")}
        </Typography>
        <Typography
          sx={{ fontWeight: "400", textAlign: "center", mt: 2, mb: 5 }}
          variant="subtitle1"
        >
          {t("layout.pricingSection.subtitle")}
        </Typography>

        {isXs ? (
          <div className="landing-carousel-wrapper">
            <Carousel responsive={responsive} infinite showDots>
              {cards.map((card, index) => (
                <div key={index}>{renderCard(card, index)}</div>
              ))}
            </Carousel>
          </div>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {cards.map((card, index) => renderCard(card, index))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}
