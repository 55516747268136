import { AuthorizedUser } from "./models/authorizedUser";

export const getAuthorizedUser = (): AuthorizedUser => {
  return JSON.parse(localStorage.getItem("authorizedUser") as string);
};

export const setAuthorizedUser = (authorizedUser: AuthorizedUser): void => {
  return localStorage.setItem("authorizedUser", JSON.stringify(authorizedUser));
};

export const logoutAuthorizedUser = (): void => {
  return localStorage.removeItem("authorizedUser");
};
// calculate time to expire token

// refresh token
