import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Login from "./dashboard-containers/Login.tsx";
import "./Dashboard.css";
import CoalaAppBar from "../components/CoalaAppBar.tsx";
import SocialMediaAssystent from "./dashboard-containers/SocialMediaAssystent.tsx";
import { Button, Divider, ThemeProvider, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Home from "./dashboard-containers/Home.tsx";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { logoutAuthorizedUser } from "../http/local-storage/auth/AuthProvider.ts";
import {
  AuthState,
  getCurrentUser,
  logout,
} from "../http/store/reducers/auth.ts";
import { SocialMediaState } from "../http/store/reducers/social-media.ts";
import mainPageIcon from "../assets/drawer_main_page.svg";
import drawerProfileIcon from "../assets/drawer_profile.svg";
import Profile from "./dashboard-containers/Profile.tsx";
import { theme } from "../theme.ts";
import { removeSocialMedia } from "../http/local-storage/config/SocialMediaFormProvider.ts";

const drawerWidth = 250;

export default function ResponsiveDrawer({ window, t }) {
  const [isMobileOpen, setMobileOpen] = useState(false);
  const auth = useSelector((state: { auth: AuthState }) => state.auth);
  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getCurrentUser({}));
  }, [dispatch]);

  const handleIsOpen = (mobileOpen: boolean) => {
    setMobileOpen(mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  function onLogout() {
    dispatch(logout(auth.currentUser?.id)).then(({ payload }) => {
      if (payload === "error") {
        return;
      }
      logoutAuthorizedUser();
      removeSocialMedia()
    });
  }

  const drawer = (
    <Box mt={2}>
      <List>
        <ListItem disablePadding>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "210px",
            }}
            mt={11}
            mb={3}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "20px",
              }}
              component="div"
            >
              {auth?.currentUser?.authorizedUser.isVerified &&
                `${auth.currentUser.authorizedUser.username}`}
            </Typography>
          </Box>
        </ListItem>
        <ListItem disablePadding>
          <Link to="./home" style={{ textDecorationColor: "transparent" }}>
            <Button
              sx={{
                display: "flex",
                justifyContent: "left",

                padding: "20px",
                width: "250px",
              }}
              disabled={!auth.currentUser?.authorizedUser.isVerified}
              startIcon={
                <img
                  src={mainPageIcon}
                  alt="main page icon"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "13px",
                }}
                component="div"
              >
                {t("resposiveDrawer.mainPage")}
              </Typography>
            </Button>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="./profile" style={{ textDecorationColor: "transparent" }}>
            <Button
              sx={{
                display: "flex",
                justifyContent: "left",

                padding: "20px",
                width: "250px",
              }}
              disabled={!auth.currentUser?.authorizedUser.isVerified}
              startIcon={
                <img
                  src={drawerProfileIcon}
                  alt="profile icon"
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "13px",
                }}
                component="div"
              >
                {t("resposiveDrawer.profile")}
              </Typography>
            </Button>
          </Link>
        </ListItem>
        {/*

        <ListItem disablePadding>
          <Link to="./home" style={{ textDecorationColor: "transparent" }}>
            <Button
              sx={{
                display: "flex",
                justifyContent: "left",
                padding: "20px",
                width: "250px",
              }}
              disabled={!auth.currentUser?.authorizedUser.isVerified}
              startIcon={<StoreIcon />}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
                component="div"
              >
                {t("resposiveDrawer.brand")}
              </Typography>
            </Button>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link to="./home" style={{ textDecorationColor: "transparent" }}>
            <Button
              sx={{
                display: "flex",
                justifyContent: "left",
                padding: "20px",
                width: "250px",
              }}
              disabled={!auth.currentUser?.authorizedUser.isVerified}
              startIcon={<ArchiveIcon />}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
                component="div"
              >
                {t("resposiveDrawer.archive")}
              </Typography>
            </Button>
          </Link>
        </ListItem>
          */}
        <ListItem
          disablePadding
          sx={{ width: "250px", position: "fixed", bottom: 0 }}
        >
          <Link to="./home" style={{ textDecorationColor: "transparent" }}>
            {auth.currentUser?.authorizedUser.isVerified ? (
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  width: "250px",
                }}
                onClick={() => onLogout()}
                startIcon={
                  <LogoutIcon sx={{ width: "30px", height: "30px" }} />
                }
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  component="div"
                >
                  {t("resposiveDrawer.logout")}
                </Typography>
              </Button>
            ) : (
              ""
            )}
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {auth?.isLoading || socialMedia.isLoading ? (
        <Box sx={{ width: "100%", position: "fixed" }}>
          <LinearProgress color="secondary" />
        </Box>
      ) : (
        ""
      )}
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />

        <Box
          component="nav"
          mt={5}
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <CoalaAppBar mobileOpen={handleIsOpen} t={t} />
          <Drawer
            container={container}
            variant="temporary"
            open={isMobileOpen}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                border: "none",
                background: (theme) => theme.palette.gradient.light,
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                border: "none",
                background: (theme) => theme.palette.gradient.light,
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingLeft: 3,
            background: "rgba(80, 88, 118, 0.045)",
            paddingRight: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)`, xs: `100%` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route
              path="/home"
              element={
                auth.currentUser?.authorizedUser.isVerified ? (
                  <Home t={t} />
                ) : (
                  <Login t={t} />
                )
              }
            />

            <Route path="*" element={<Navigate replace to="/home" />}></Route>

            <Route
              path="/profile"
              element={
                auth.currentUser?.authorizedUser.isVerified ? (
                  <Profile t={t} />
                ) : (
                  <Login t={t} />
                )
              }
            />

            <Route
              path="/assystent"
              element={
                auth.currentUser?.authorizedUser.isVerified ? (
                  <SocialMediaAssystent t={t} />
                ) : (
                  <Login t={t} />
                )
              }
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
}
