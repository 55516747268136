import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../config/axiosInstance";
import { baseURL } from "../../config/api";
import {
  IGenerateImagesInput,
  IScoreOutputTextInput,
  IScoreOutputTextWithImagesInput,
  ISocialMediaGeneratorInput,
  OutputTextWithScore,
  TextScore,
} from "../../../models/social-media-assystent";
import axios from "axios";

export interface SocialMediaState {
  socialMediaAssystent: OutputTextWithScore[];
  demo: OutputTextWithScore[];
  isLoading: boolean;
  isError: boolean;
  tokensExpired: boolean;
  score: TextScore[];
  images: {
    image: string;
    imageExtension: string;
  }[];
}

const initialState = {
  socialMediaAssystent: undefined,
  demo: undefined,
  isLoading: false,
  isError: false,
  tokensExpired: false,
  score: undefined,
} satisfies any as any;

export const checkIfTokensExpired = (detail: string): boolean => {
  return detail === "User used all tokens for this month";
};

export const generateSocialMedia = createAsyncThunk(
  "socialMedia/generate",
  async (inputData: ISocialMediaGeneratorInput, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/social_media_generator/generate_social_media_texts`,
        inputData
      );

      return response.data;
    } catch (err) {
      const tokensExpired = checkIfTokensExpired(err.response.data.detail);

      return thunkAPI.rejectWithValue(
        tokensExpired ? "tokensExpired" : "error"
      );
    }
  }
);

export const generateDemoSocialMedia = createAsyncThunk(
  "socialMedia/demo",
  async (inputData: ISocialMediaGeneratorInput, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/social_media_generator/demo_generate_social_media_texts`,
        inputData
      );

      return response.data;
    } catch (err) {

      return "error"
    }
  }
);

export const generateScore = createAsyncThunk(
  "socialMedia/scoreGenerate",
  async (
    inputData: IScoreOutputTextInput | IScoreOutputTextWithImagesInput,
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/social_media_generator/score_post`,
        inputData
      );

      return response.data;
    } catch (err) {
      const tokensExpired = checkIfTokensExpired(err.response.data.detail);

      return thunkAPI.rejectWithValue(
        tokensExpired ? "tokensExpired" : "error"
      );
    }
  }
);

export const generatePexelImages = createAsyncThunk(
  "socialMedia/generatePexelImages",
  async (inputData: IGenerateImagesInput, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/social_media_generator/search_images`,
        inputData
      );

      return response.data;
    } catch (err) {
      const tokensExpired = checkIfTokensExpired(err.response.data.detail);

      return thunkAPI.rejectWithValue(
        tokensExpired ? "tokensExpired" : "error"
      );
    }
  }
);

const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder
      .addCase(generateSocialMedia.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateSocialMedia.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.tokensExpired = false;
        state.socialMediaAssystent = action.payload;
      })
      .addCase(generateSocialMedia.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload === "tokensExpired") {
          state.tokensExpired = true;
        } else {
          state.isError = true;
        }
      })
      .addCase(generateDemoSocialMedia.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateDemoSocialMedia.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.demo = action.payload;
      })
      .addCase(generateDemoSocialMedia.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(generateScore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.tokensExpired = false;
        state.score = action.payload;
      })
      .addCase(generateScore.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload === "tokensExpired") {
          state.tokensExpired = true;
        } else {
          state.isError = true;
        }
      })

      .addCase(generatePexelImages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generatePexelImages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.tokensExpired = false;
        state.images = action.payload;
      })
      .addCase(generatePexelImages.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload === "tokensExpired") {
          state.tokensExpired = true;
        } else {
          state.isError = true;
        }
      });
  },
});
export default socialMediaSlice.reducer;
