import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Grid,
  useMediaQuery,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  generateScore,
  generateSocialMedia,
  SocialMediaState,
} from "../../../../http/store/reducers/social-media";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PostScore from "../components/PostScore";
import RadarChart from "../components/RadarChart";

import { getAuthorizedUser } from "../../../../http/local-storage/auth/AuthProvider";
import ScoreTable from "../components/ScoreTable.tsx";
import { theme } from "../../../../theme.ts";
import {
  IScoreOutputTextInput,
  OutputText,
  OutputTextWithScore,
  TextScore,
} from "../../../../models/social-media-assystent.ts";
import uuid from "react-uuid";
import { scroller } from "react-scroll";

export const FourthStepView = ({
  inputData,
  formChanged,
  setFormChanged,
  outputTexts,
  setOutputTexts,
  generateImages,
  t,
}) => {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch<any>();

  const [activeCurrentSlide, setActiveCurrentSlide] = useState<number>(
    isXS ? 0 : 1
  );

  const [isScoreLoading, setIsScoreLoading] = useState<boolean>(false);

  const [isTable, setIsTable] = useState(true);

  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselRef: any = useRef();

  const onSetEditedText = (text: any, editedPostIndex: number) => {
    scorePost(text, editedPostIndex);
  };

  const moveItem = (
    outputTextsWithScores: OutputTextWithScore[],
    fromIndex: number,
    toIndex: number
  ) => {
    const outputTextsWithScopresCopy = [...outputTextsWithScores];
    outputTextsWithScopresCopy.splice(
      toIndex,
      0,
      outputTextsWithScopresCopy.splice(fromIndex, 1)[0]
    );
    return outputTextsWithScopresCopy;
  };

  const setActivePost = (previousSlide, currentSlide, dataSize): void => {
    if (isScoreLoading) {
      return;
    }

    let activeSlide = 0;
    // right arrow
    if (previousSlide < currentSlide)
      activeSlide = currentSlide - 2 === dataSize ? 0 : currentSlide - 2;
    // left arrow
    else
      activeSlide =
        currentSlide +
        (currentSlide <= dataSize && currentSlide >= 2 ? -2 : dataSize - 2);
    setActiveCurrentSlide(activeSlide);
  };

  const regenerate = (editedPostIndex: number) => {
    const regenerateInput = {
      ...inputData,
      llm_input: {
        ...inputData.llm_input,
        numTxtGenerate: 1,
      },
    };
    setOutputTexts([]);
    setIsScoreLoading(true);
    dispatch(generateSocialMedia(regenerateInput)).then(({ payload }) => {
      if (!payload) {
        return;
      }
      const outputTextsCopy: OutputTextWithScore[] = [
        ...sortPosts(outputTexts),
      ];

      const outputText: string = (payload.outputTexts[0] as OutputText)
        .postText;
      scorePost(outputText, editedPostIndex, outputTextsCopy);
    });
  };

  const scorePost = (
    editedText: string,
    editedPostIndex: number,
    outputTextsCopyFromGenerate?: OutputTextWithScore[]
  ) => {
    const outputTextsCopy: OutputTextWithScore[] = outputTextsCopyFromGenerate
      ? outputTextsCopyFromGenerate
      : [...sortPosts(outputTexts)];
    setOutputTexts([]);
    setIsScoreLoading(true);

    const userToken = getAuthorizedUser().authorizedUser.token;
    const activeIndex = lessThanXL ? activeCurrentSlide : editedPostIndex;
    try {
      const scoreInputData: IScoreOutputTextInput = {
        user_token: {
          idToken: userToken,
        },
        score_text_input: {
          text: editedText,
          contentBrief: inputData.llm_input.contentBrief,
          score_image_input: null,
        },
      };
      return dispatch(generateScore(scoreInputData)).then((value) => {
        const outputTextsWithNewScore: OutputTextWithScore[] = [
          ...outputTextsCopy.map((outputWithScore, i) => {
            if (i === activeIndex) {
              return {
                ...outputWithScore,
                postText: editedText,
                score: value.payload,
              };
            } else {
              return outputWithScore;
            }
          }),
        ];
        const outputTextsWithScoresSorted: OutputTextWithScore[] =
          outputTextsWithNewScore.sort((a, b) => {
            return b.score.totalScore - a.score.totalScore;
          });

        const firstPlaceAtSecondPosition: OutputTextWithScore[] = moveItem(
          outputTextsWithScoresSorted,
          0,
          1
        );

        setOutputTexts([...firstPlaceAtSecondPosition]);
        setFormChanged(false);
        setIsScoreLoading(false);
      });
    } catch (err) {
      console.log(err);
      setIsScoreLoading(false);
    }
  };

  const scorePosts = (outputTextsValue: OutputText[]) => {
    const userToken = getAuthorizedUser().authorizedUser.token;
    try {
      const fetchCarouselPromises = outputTextsValue.map(
        (output: OutputText) => {
          const scoreInputData: IScoreOutputTextInput = {
            user_token: {
              idToken: userToken,
            },
            score_text_input: {
              text: output.postText,
              contentBrief: inputData.llm_input.contentBrief,
              score_image_input: null,
            },
          };
          return dispatch(generateScore(scoreInputData));
        }
      );
      Promise.all(fetchCarouselPromises).then((values) => {
        const scores: TextScore[] = values.map((v) => v.payload);
        mergeOutputTextsWithScores(outputTextsValue, scores);
        setFormChanged(false);

        if (lessThanXL) {
          setTimeout(() => {
            carouselRef?.current?.next();
          }, 100);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const mergeOutputTextsWithScores = (
    outputTextsValue: OutputText[],
    scores: TextScore[]
  ): void => {
    const outputWithScores: OutputTextWithScore[] = (
      outputTextsValue.map((output, outputIndex) => ({
        ...output,
        score: scores.find(
          (score: TextScore, scoreIndex: number) => scoreIndex === outputIndex
        ),
      })) as OutputTextWithScore[]
    ).sort((a: OutputTextWithScore, b: OutputTextWithScore) => {
      return b.score.totalScore - a.score.totalScore;
    });
    const firstPlaceAtSecondPosition = moveItem(outputWithScores, 0, 1);
    setOutputTexts(firstPlaceAtSecondPosition);
  };

  const sortPosts = (texts: OutputTextWithScore[]) => {
    if (!texts.length) {
      return [];
    }
    const sortedOutputTexts = texts.sort(
      (a: OutputTextWithScore, b: OutputTextWithScore) => {
        return b.score.totalScore - a.score.totalScore;
      }
    );
    const firstPlaceAtSecondPosition = moveItem(sortedOutputTexts, 0, 1);

    return firstPlaceAtSecondPosition;
  };

  useEffect(() => {
    if (inputData && formChanged) {
      setOutputTexts([]);
      dispatch(generateSocialMedia(inputData)).then(({ payload }) => {
        if (!payload) {
          return;
        }
        const outputTexts: OutputText[] = payload.outputTexts;

        scorePosts(outputTexts);
      });
    }
  }, []);

  const handleToggle = (event) => {
    setIsTable(event.target.checked);
    scroller.scrollTo("title-analysis-generator", {
      duration: 700,
      smooth: true,
    });
  };

  return (
    <Box>
      {socialMedia.isError && !socialMedia.isLoading ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.somethingWrong")}</Alert>
        </Box>
      ) : (
        ""
      )}
      {socialMedia.tokensExpired ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.tokensExpired")}</Alert>
        </Box>
      ) : (
        ""
      )}
      {!outputTexts?.length &&
      (!socialMedia.isError || socialMedia.isLoading) &&
      !socialMedia.tokensExpired ? (
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {t("SMAsistant.Step4.loaderScoreText")}{" "}
          <CircularProgress
            size="1.5rem"
            sx={{ marginLeft: "1.5rem" }}
            disableShrink
            color="secondary"
          />
        </Grid>
      ) : (
        <Grid item md={12}>
          {lessThanXL ? (
            <Box
              sx={{
                display: "grid",
              }}
            >
              <Carousel
                ref={carouselRef}
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={false}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
                containerClass="carousel-container"
                itemClass="p-3"
                afterChange={(previousSlide, active) => {
                  setActivePost(previousSlide, active.currentSlide, 3);
                }}
                className="fourth-step-carousel"
              >
                {sortPosts(outputTexts).map(
                  (outputTextWithScore: OutputTextWithScore, index: number) => {
                    return (
                      <PostScore
                        regenerate={() => regenerate(index)}
                        key={index}
                        outputTextWithScore={outputTextWithScore}
                        activeCurrentSlide={activeCurrentSlide}
                        index={index}
                        setEditedText={(text: string) =>
                          onSetEditedText(text, index)
                        }
                        isScoreLoading={isScoreLoading}
                        generateImages={generateImages}
                        t={t}
                        fromEditVariants={false}
                        socialMediaTarget={null}
                      />
                    );
                  }
                )}
              </Carousel>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {sortPosts(outputTexts).map(
                (outputTextWithScore: OutputTextWithScore, index: number) => {
                  return (
                    index < 3 && (
                      <Grid key={uuid()} item md={4}>
                        <PostScore
                          regenerate={() => regenerate(index)}
                          outputTextWithScore={outputTextWithScore}
                          activeCurrentSlide={activeCurrentSlide}
                          index={index}
                          setEditedText={(text) => onSetEditedText(text, index)}
                          isScoreLoading={isScoreLoading}
                          generateImages={generateImages}
                          t={t}
                          fromEditVariants={false}
                          socialMediaTarget={null}
                        />
                      </Grid>
                    )
                  );
                }
              )}
            </Grid>
          )}
        </Grid>
      )}

      <Grid container mt={2} spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            textAlign: "center",
            padding: "0",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {outputTexts?.length ? (
            <>
              {/* Title on the left */}
              <Typography
                id="title-analysis-generator"
                sx={{
                  fontWeight: "bold",
                  opacity: 0.3,
                  paddingLeft: 2, // Optional padding for left alignment
                }}
              >
                {t("SMAsistant.Step4.analysisTitle")}
              </Typography>

              <Box>
                {/* Conditionally render the Toggle Switch only if not extra small screen */}
                {!isXS && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 2,
                    }}
                  >
                    {/* Left label */}
                    <Typography
                      variant="body1"
                      sx={{
                        mr: 1,
                        color: !isTable ? "black" : "grey",
                        fontWeight: !isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabPlot")}
                    </Typography>

                    <Switch
                      checked={isTable}
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "Chart or Table switch" }}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: theme.palette.figma.secondary.orange.second,
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor:
                              theme.palette.figma.secondary.orange.second,
                          },
                        "& .MuiSwitch-track": {
                          backgroundColor: "grey",
                        },
                      }}
                    />

                    {/* Right label */}
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        color: isTable ? "black" : "grey",
                        fontWeight: isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabTable")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          ) : null}
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: isTable ? "flex" : undefined,
            textAlign: "center",
            padding: "0",
            justifyContent: "center",
          }}
        >
          {outputTexts?.length ? (
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Box>
                  {!isTable ? (
                    <Box>
                      <RadarChart
                        generateImages={generateImages}
                        outputTexts={sortPosts(outputTexts)}
                        t={t}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <ScoreTable
                        outputTexts={sortPosts(outputTexts)}
                        activeCurrentSlide={activeCurrentSlide}
                        t={t}
                      />
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
