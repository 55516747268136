import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Card, CardContent, Container } from "@mui/material";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FiTarget } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";
import { theme } from "../../theme.ts";

export default function RecipentsSection({ t }) {
  return (
    <Box
  
      sx={{
        background: "linear-gradient(131deg, #1F1958 0%, 75%, #763E5F 100%)",
        color: "white",
        padding: "75px 0 75px",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={10}>
          {[
            {
              icon: (
                <FaArrowTrendUp
                  style={{
                    color: theme.palette.figma.primary.yellow.third,
                    width: 40,
                    height: 40,
                  }}
                />
              ),
              titleKey: "card1Title",
              textKey: "card1Text",
            },
            {
              icon: (
                <FiTarget
                  style={{
                    color: theme.palette.figma.primary.yellow.third,
                    width: 40,
                    height: 40,
                  }}
                />
              ),
              titleKey: "card2Title",
              textKey: "card2Text",
            },
            {
              icon: (
                <FiShoppingCart
                  style={{
                    color: theme.palette.figma.primary.yellow.third,
                    width: 40,
                    height: 40,
                  }}
                />
              ),
              titleKey: "card3Title",
              textKey: "card3Text",
            },
          ].map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                {item.icon}
                <Typography
                  sx={{ fontWeight: "600", my: 1, textTransform: "uppercase" }}
                  variant="h7"
                  component="div"
                >
                  {t(`layout.recipientsSection.${item.titleKey}`)}
                </Typography>
              </Box>
              <Card
                variant="outlined"
                sx={{
                  height: "70%",
                  display: "flex",
                  flexDirection: "column",
                  background:
                    "linear-gradient(180deg, rgba(168, 130, 221, 0.2) 0%, rgba(168, 130, 221, 0) 100%)",
                  borderRadius: "24px",
                  borderWidth: "0px", // Reduced border thickness
                }}
              >
                <CardContent sx={{ flexGrow: 1, pb: 3 }}>
                  <Typography
                    variant="body2"
                    m={1}
                    sx={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "0.9rem",
                      lineHeight: 1.5,
                    }}
                  >
                    {t(`layout.recipientsSection.${item.textKey}`)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
