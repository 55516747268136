import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { theme } from "../../theme.ts";

export default function AboutUsSection({ t }) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      id="about-us-section"
      sx={{
        background: "linear-gradient(180deg, #544156 0%, 35%, #1E1857 100%)",
        color: "white",
        padding: "75px 0 125px",
        display: "flex",
        justifyContent: "left",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={10}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "left", mb: 8, mt: 4 }}>
              <Typography
                variant="h5"
                color={(theme) => theme.palette.figma.primary.yellow.third}
                gutterBottom
              >
                {t("layout.aboutUsSection.section1.title")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("layout.aboutUsSection.section1.text")}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "left", mb: isXS ? 0 : 5 }}>
              <Typography
                variant="h5"
                color={(theme) => theme.palette.figma.primary.yellow.third}
                gutterBottom
              >
                {t("layout.aboutUsSection.section2.title")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("layout.aboutUsSection.section2.text")}
              </Typography>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "left", mb: 5, mt: isXS ? 0 : 4 }}>
              <Typography
                variant="h5"
                color={(theme) => theme.palette.figma.primary.yellow.third}
                gutterBottom
              >
                {t("layout.aboutUsSection.section3.title")}
              </Typography>
              <Typography variant="body1" paragraph>
                {t("layout.aboutUsSection.section3.text")}
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "center", mb: 2, my: 4 }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<EmailIcon sx={{ color: "white" }} />}
                  href="mailto:contact@coala.com"
                  sx={{
                    color: "white",
                    borderColor: (theme) =>
                      theme.palette.figma.primary.yellow.third,
                    padding: "15px 25px",
                  }}
                >
                  {t("layout.aboutUsSection.section3.contactMail")}
                </Button>
              </Box>
              <Typography variant="body1" paragraph>
                {t("layout.aboutUsSection.section3.callToAction")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
