import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTheme, setTheme } from "../../local-storage/config/ThemeProvider";
import { getLang, setLang } from "../../local-storage/config/LangProvider";

export interface ConfigState {
  theme: string;
  lang: string;
}

const initialState = {
  theme: getTheme() ? getTheme() : "light",
  lang: navigator.language.split("-")[0],
} satisfies ConfigState as ConfigState;

export const getCurrentTheme = createAsyncThunk(
  "config/getCurrentTheme",
  async (_, thunkAPI) => {
    const theme = getTheme() as string;
    return theme;
  }
);

export const changeCurrentTheme = createAsyncThunk(
  "config/changeCurrentTheme",
  async (theme: string, thunkAPI) => {
    setTheme(theme);
    const newTheme = getTheme() as string;
    return newTheme;
  }
);

export const getCurrentLang = createAsyncThunk(
  "config/getCurrentLang",
  async (_, thunkAPI) => {
    const lang = getLang() as string;
    return lang;
  }
);

export const changeCurrentLang = createAsyncThunk(
  "config/changeCurrentLang",
  async (lang: string, thunkAPI) => {
    setLang(lang);
    const newLang = getLang() as string;
    return newLang;
  }
);

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentTheme.fulfilled, (state, action) => {
        state.theme = action.payload;
      })
      .addCase(changeCurrentTheme.fulfilled, (state, action) => {
        state.theme = action.payload;
      })
      .addCase(getCurrentLang.fulfilled, (state, action) => {
        state.lang = action.payload;
      })
      .addCase(changeCurrentLang.fulfilled, (state, action) => {
        state.lang = action.payload;
      });
  },
});
export default configSlice.reducer;
