import { Container, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import MainInfo from "../components/layout-sections/MainInfo.tsx";
import RecipentsSection from "../components/layout-sections/RecipentsSection.tsx";
import FeaturesSection from "../components/layout-sections/FeaturesSection.tsx";
import CoalaAppBar from "../components/CoalaAppBar.tsx";
import MetricsSection from "../components/layout-sections/MetricsSection.tsx";
import Footer from "../components/layout-sections/Footer.tsx";
import AboutUsSection from "../components/layout-sections/AboutUsSection.tsx";
import Testimonials from "../components/layout-sections/Testimonials.jsx";
import Pricing from "../components/layout-sections/Pricing.tsx";
import { theme } from "../theme.ts";
import DemoSection from "../components/layout-sections/DemoSection.tsx";

function Layout({ t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <CoalaAppBar mobileOpen={null} t={t} />
      <Box sx={{ flexGrow: 1 }}>
        <Container disableGutters={!lessThanXL}>
          <MainInfo t={t} />
        </Container>
        <DemoSection t={t} />
        <RecipentsSection t={t} />
        <FeaturesSection t={t} />
        <MetricsSection t={t} />
        <Container disableGutters={!lessThanXL}>
          <Pricing t={t} />
        </Container>
        <Testimonials t={t} />
        <AboutUsSection t={t} />
        <Footer t={t} />
      </Box>
    </>
  );
}

export default Layout;
