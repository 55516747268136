import {
  ISocialMediaAssystentForm,
  ISocialMediaGeneratorInput,
  OutputTextAndImagesWithScore,
  OutputTextWithScore,
} from "../../../models/social-media-assystent";

export const getSocialMediaFormLocalStorage = (): ISocialMediaAssystentForm => {
  return JSON.parse(localStorage.getItem("socialMediaForm") as string);
};

export const setSocialMediaFormLocalStorage = (
  form: ISocialMediaAssystentForm
): void => {
  return localStorage.setItem("socialMediaForm", JSON.stringify(form));
};

export const getActiveStepLocalStorage = (): number => {
  return JSON.parse(localStorage.getItem("activeStep") as string);
};

export const setActiveStepLocalStorage = (activeStep: number): void => {
  return localStorage.setItem("activeStep", JSON.stringify(activeStep));
};

export const getInputDataLocalStorage = (): ISocialMediaGeneratorInput => {
  return JSON.parse(localStorage.getItem("inputData") as string);
};

export const setInputDataLocalStorage = (
  inputData: ISocialMediaGeneratorInput | null
): void => {
  return localStorage.setItem("inputData", JSON.stringify(inputData));
};

export const getOutputTextsWithScoreLocalStorage =
  (): OutputTextWithScore[] => {
    return JSON.parse(localStorage.getItem("outputTexts") as string);
  };

export const setOutputTextsWithScoreLocalStorage = (
  outputTexts: OutputTextWithScore[]
): void => {
  return localStorage.setItem("outputTexts", JSON.stringify(outputTexts));
};

export const getOutputTextsWithScoreAndImagesLocalStorage =
  (): OutputTextWithScore[] => {
    return JSON.parse(localStorage.getItem("outputTextsWithImages") as string);
  };

export const setOutputTextsWithScoreAndImagesLocalStorage = (
  outputTextsWithImages: OutputTextAndImagesWithScore[]
): void => {
  return localStorage.setItem(
    "outputTextsWithImages",
    JSON.stringify(outputTextsWithImages)
  );
};

export const removeSocialMedia = (): void => {
  localStorage.removeItem("socialMediaForm");
  localStorage.removeItem("activeStep");
  localStorage.removeItem("inputData");
  localStorage.removeItem("outputTexts");
  localStorage.removeItem("outputTextsWithImages");
};

