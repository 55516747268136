import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  Card,
  CardContent,
  Grid,
  StepButton,
  StepIconProps,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../theme.ts";
import FirstStepMainInfo from "../../components/dashboard-components/social-media-assystant/stepper/FirstStepMainInfo.tsx";
import { styled } from "@mui/material/styles";
import { IoPeople } from "react-icons/io5";
import { IoChatbubbles } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import SecondStepVoice from "../../components/dashboard-components/social-media-assystant/stepper/SecondStepVoice.tsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ThirdStepAudience from "../../components/dashboard-components/social-media-assystant/stepper/ThirdStepAudience.tsx";
import { FourthStepView } from "../../components/dashboard-components/social-media-assystant/stepper/FourthStepView.tsx";
import { AuthState } from "../../http/store/reducers/auth.ts";
import {
  ContentBrief,
  ISocialMediaAssystentForm,
  ISocialMediaGeneratorInput,
  OutputTextAndImagesWithScore,
  OutputTextWithScore,
} from "../../models/social-media-assystent.ts";
import { FifthStepFullPost } from "../../components/dashboard-components/social-media-assystant/stepper/FifthStepFullPost.tsx";
import { SocialMediaState } from "../../../../http/store/reducers/social-media";
import {
  getActiveStepLocalStorage,
  getInputDataLocalStorage,
  getOutputTextsWithScoreLocalStorage,
  getSocialMediaFormLocalStorage,
  removeSocialMedia,
  setActiveStepLocalStorage,
  setInputDataLocalStorage,
  setOutputTextsWithScoreLocalStorage,
  setSocialMediaFormLocalStorage,
} from "../../http/local-storage/config/SocialMediaFormProvider.ts";

export default function SocialMediaAssystent({ t }) {
  const steps = [
    t("SMAsistant.steper.step1"),
    t("SMAsistant.steper.step2"),
    t("SMAsistant.steper.step3"),
  ];

  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const moreThanXL = useMediaQuery(theme.breakpoints.up("xl"));
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPexels, setIsPexels] = React.useState(false);

  const startingFormParams: ISocialMediaAssystentForm = {
    postPurpose: [],
    talkingPoints: "",
    images: [],
    brandVoice: {
      brandArchetype: "",
      brandArchetypeDescription: "",
    },
    socialMediaTarget: "instagram",
    postLength: "dynamic",
    targetAudience: [
      {
        gender: "all",
        ageRange: [16, 95],
        otherTraits: "",
      },
    ],
    contentType: "post",
    language: "polski",
    imagesExtensions: [],
  };
  const [form, setForm] =
    useState<ISocialMediaAssystentForm>(startingFormParams);
  const [formValid, setFormValid] = useState<boolean>(true);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [scoresChanged, setScoresChanged] = useState<boolean>(false);

  const [inputData, setInputData] = useState<ISocialMediaGeneratorInput | null>(
    null
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const [imagesAddedAndRefresh, setImagesAddedAndRefresh] =
    useState<boolean>(false);
  const [outputTexts, setOutputTexts] = useState<OutputTextWithScore[]>([]);
  const [outputTextsImages, setOutputTextsImages] = useState<
    OutputTextAndImagesWithScore[]
  >([]);

  const auth = useSelector((state: { auth: AuthState }) => state.auth);
  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    background: "transparent",
    border: "none", // Ensuring no border appears
    zIndex: 1,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // Apply transparent background for completed state
    ...(ownerState.completed && {
      background: "transparent",
    }),

    // Active state style
    ...(ownerState.active && {
      background: "transparent",
      borderColor: "transparent",
      color: "#f4764b",
      width: 40,
      height: 40,
      boxShadow: "none", // Removing box shadow if not needed
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement<any> } = {
      1: <IoSettings />,
      2: <IoChatbubbles />,
      3: <IoPeople />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {/* Apply dynamic styling for size increase when active */}
        {React.cloneElement(icons[String(props.icon)], {
          style: {
            transition: "font-size 0.3s ease", // Smooth transition for icon resizing
            width: 31,
            height: 31,
            color: active
              ? theme.palette.figma.gradient.gradient2
              : completed
                ? theme.palette.figma.validation.positive
                : "grey",
          },
        })}
      </ColorlibStepIconRoot>
    );
  }

  function getFiles(files: File[]): Promise<string[]> {
    if (!files.length) {
      return Promise.all([]);
    }
    return Promise.all<string>(files.map(getBase64));
  }

  async function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  }

  const handleStep = (step: number) => () => {
    generateSocialMedia();
    if (step > activeStep && !validateStepper()) {
      return;
    }

    if (step < activeStep) {
      setActiveStep(step);
    } else {
      const { socialMediaTarget, language } = form;
      if (step === 2 && !socialMediaTarget) {
        setActiveStep(1);
      } else if (step === 3 && !language) {
        setActiveStep(2);
      } else {
        setActiveStep(step);
      }
    }
  };

  const validateStepper = (): boolean => {
    const {
      postPurpose,
      talkingPoints,
      brandVoice,
      socialMediaTarget,
      postLength,
      targetAudience,
      language,
    } = form;

    if (activeStep === 0 && (!postPurpose.length || !talkingPoints)) {
      setFormValid(false);
      return false;
    } else if (
      activeStep === 1 &&
      (!brandVoice.brandArchetype ||
        !brandVoice.brandArchetypeDescription ||
        !socialMediaTarget ||
        !postLength)
    ) {
      setFormValid(false);
      return false;
    } else if (
      activeStep === 2 &&
      (!targetAudience[0].ageRange ||
        !targetAudience[0].gender ||
        !targetAudience[0].otherTraits ||
        !language)
    ) {
      setFormValid(false);
      return false;
    } else {
      setFormValid(true);
      return true;
    }
  };

  const handleOutputText = (
    outputTexts: OutputTextWithScore[] | OutputTextAndImagesWithScore[]
  ) => {
    setInputDataLocalStorage(inputData);
    setScoresChanged(true);
    setOutputTexts(outputTexts);
    setOutputTextsWithScoreLocalStorage(outputTexts);
    setOutputTextsImages(outputTexts);
  };

  const handleNext = () => {
    if (!validateStepper()) {
      return;
    }
    generateSocialMedia();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    const formFromLocalStorage: ISocialMediaAssystentForm =
      getSocialMediaFormLocalStorage() || form;

    const activeStepFromLocalStorage: number = getActiveStepLocalStorage() || 0;
    const outputTextsFromLocalStorage: OutputTextWithScore[] =
      getOutputTextsWithScoreLocalStorage() || outputTexts;
    const inputDataLocalStorage: ISocialMediaGeneratorInput =
      getInputDataLocalStorage() || inputData;

    setOutputTexts(outputTextsFromLocalStorage);
    setOutputTextsImages(outputTextsFromLocalStorage);
    setInputData(inputDataLocalStorage);
 
    if (
      activeStepFromLocalStorage < 3 &&
      formFromLocalStorage.images.length &&
      formFromLocalStorage.images[0].base64 === "imagesAdded"
    ) {
      setActiveStep(0);
      setImagesAddedAndRefresh(true);
    } else {
      setActiveStep(activeStepFromLocalStorage);
    }
    setForm({ ...formFromLocalStorage, images: [] });
    if (!outputTextsFromLocalStorage) {
      setFormChanged(true)
    } else {
      setScoresChanged(true);
    }
    setSocialMediaFormLocalStorage({
      ...form,
      images: [],
    });
  }, []);

  useEffect(() => {
    setActiveStepLocalStorage(activeStep);
  }, [activeStep]);

  const handleFormChange = (form: ISocialMediaAssystentForm) => {
    if (!form) {
      return;
    }
    setImagesAddedAndRefresh(false);
    setForm(form);
    setFormChanged(true);
    setSocialMediaFormLocalStorage({
      ...form,
      images: form.images.length ? [{ base64: "imagesAdded", name: "" }] : [],
      imagesExtensions: [],
    });
    setIsPexels(false);
  };

  const generateSocialMedia = () => {
    if (activeStep === 2) {
      const bodyData: ContentBrief = {
        postPurpose: (form.postPurpose as string[])
          .map((item) => item["name"])
          .join(", "),
        talkingPoints: form.talkingPoints,
        brandVoice: form.brandVoice,
        socialMediaTarget: form.socialMediaTarget,
        targetAudience: form.targetAudience.map((audience) => {
          return {
            ...audience,
            ageRange: `${audience.ageRange[0]} - ${audience.ageRange[1]}`,
          };
        }),
        contentType: "post",
        language: form.language,
        postLength: form.postLength,
      };
      setInputData({
        user_token: {
          idToken: auth.currentUser.authorizedUser.token,
        },
        llm_input: {
          contentBrief: bodyData,
          images: form.images.map((img, index: number) => ({
            imageBase64: img.base64.split("base64,")[1],
            imageExtension: form.imagesExtensions[index],
          })),

          numTxtGenerate: 3,
        },
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const restartGenerator = () => {
    removeSocialMedia();
    setForm(startingFormParams);
    setFormValid(true);
    setFormChanged(false);
    setScoresChanged(false);
    setInputData(null);
    setActiveStep(0);
    setOutputTexts([]);
    setOutputTextsImages([]);
    setIsPexels(false);
  };

  return (
    <Box
      maxWidth="xl"
      sx={{
        marginLeft: moreThanXL ? "auto" : undefined,
        marginRight: moreThanXL ? "auto" : undefined,
      }}
    >
      {activeStep < 3 ? (
        <Grid
          container
          mt={isXS ? 2 : -2}
          mb={13}
          spacing={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            container
            sx={{ width: "100%" }}
            item
            md={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: "600",
                  color: "grey",
                }}
                variant="h6"
                component="div"
              >
                {activeStep < 4
                  ? t("SMAsistant.MainTitle1")
                  : t("SMAsistant.MainTitle2")}
              </Typography>
            </Grid>
            {activeStep >= 3 && (
              <Grid item>
                <Button
                  endIcon={<DeleteForeverRoundedIcon />}
                  sx={{
                    marginLeft: "5px",
                    minHeight: "44px",
                    background: "#f5f5f5",
                    textTransform: "none",
                    fontSize: "12px",
                    color: "grey",
                    borderRadius: "8px",
                  }}
                  variant="contained"
                  onClick={restartGenerator}
                >
                  {t("SMAsistant.formReset")}
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid item md={12}>
            <Card variant="outlined">
              <CardContent sx={{ padding: isXS ? "13px" : "50px" }}>
                {activeStep > 2 ? (
                  ""
                ) : (
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation={"horizontal"}
                  >
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {
                        completed: index < activeStep, // Mark as completed if previous steps are completed
                      };
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};

                      return (
                        <Step key={label} {...stepProps}>
                          <StepButton
                            color="inherit"
                            onClick={handleStep(index)}
                          >
                            <StepLabel
                              StepIconComponent={ColorlibStepIcon}
                              {...labelProps}
                            >
                              <Typography
                                sx={{
                                  background:
                                    theme.palette.figma.gradient.gradient2,
                                  WebkitBackgroundClip: "text", // Clipping the background to the text
                                  WebkitTextFillColor: "transparent", // Making text fill transparent
                                }}
                                component="div"
                              >
                                {activeStep === index && !lessThanXL
                                  ? label
                                  : ""}
                              </Typography>
                            </StepLabel>
                          </StepButton>
                        </Step>
                      );
                    })}
                  </Stepper>
                )}

                <React.Fragment>
                  <Box>
                    {activeStep === 0 && (
                      <FirstStepMainInfo
                        imagesAddedAndRefresh={imagesAddedAndRefresh}
                        formValid={formValid}
                        form={form}
                        setForm={handleFormChange}
                        t={t}
                      />
                    )}

                    {activeStep === 1 && (
                      <SecondStepVoice
                        formValid={formValid}
                        form={form}
                        setForm={handleFormChange}
                        t={t}
                      />
                    )}

                    {activeStep === 2 && (
                      <ThirdStepAudience
                        formValid={formValid}
                        form={form}
                        setForm={handleFormChange}
                        t={t}
                      />
                    )}
                  </Box>
                </React.Fragment>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid item md={12}>
          <Grid
            container
            sx={{ width: "100%" }}
            item
            md={12}
            mb={2}
            mt={isXS ? 2 : -2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: "600",
                  color: "grey",
                }}
                variant="h6"
                component="div"
              >
                {activeStep < 4
                  ? t("SMAsistant.MainTitle1")
                  : t("SMAsistant.MainTitle2")}
              </Typography>
            </Grid>
            {activeStep >= 3 && (
              <Grid item>
                <Button
                  endIcon={<DeleteForeverRoundedIcon />}
                  sx={{
                    marginLeft: "5px",
                    minHeight: "44px",
                    background: "#f5f5f5",
                    textTransform: "none",
                    fontSize: "12px",
                    color: "grey",
                    borderRadius: "8px",
                  }}
                  variant="contained"
                  onClick={restartGenerator}
                >
                  {t("SMAsistant.formReset")}
                </Button>
              </Grid>
            )}
          </Grid>
          <React.Fragment>
            {activeStep === 3 && inputData && (
              <Box mb={13}>
                <FourthStepView
                  inputData={inputData}
                  formChanged={formChanged}
                  setFormChanged={setFormChanged}
                  outputTexts={outputTexts}
                  setOutputTexts={handleOutputText}
                  generateImages={false}
                  t={t}
                />
              </Box>
            )}

            {activeStep === 4 && inputData && (
              <Box mb={13}>
                <FifthStepFullPost
                  inputData={inputData}
                  setFormChanged={setFormChanged}
                  outputTexts={outputTextsImages}
                  setOutputTexts={setOutputTextsImages}
                  generateImages={true}
                  scoresChanged={scoresChanged}
                  setScoresChanged={setScoresChanged}
                  isPexels={isPexels}
                  setIsPexels={setIsPexels}
                  t={t}
                />
              </Box>
            )}
          </React.Fragment>
        </Grid>
      )}
      <Grid item md={12}>
        <Box
          sx={{
            background: (theme) => theme.palette.figma.gradient.gradient6,
            width: "100%",
            height: "60px",
            position: "fixed",
            left: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: isXS ? "space-between" : "flex-end",
            paddingLeft: isXS ? "20px" : "50px",
            paddingRight: isXS ? "20px" : "24px",
          }}
        >
          {/* Button 1: Aligns to the left on XS screens */}
          <Button
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              minWidth: "111px",
              mr: isXS ? 0 : 3,
              ml: isXS ? 5 : 0, // Remove right margin on XS screens for left alignment
              fontWeight: 400,
              textTransform: "none",
              fontSize: "18px",
            }}
          >
            {t("SMAsistant.back")}
          </Button>

          {activeStep < 4 && (
            // Button 2: Aligns to the right on XS screens
            <Button
              sx={{
                minWidth: "183px",
                minHeight: "44px",
                background: (theme) => theme.palette.gradient.toTop,
                fontWeight: 400,
                borderRadius: "8px",
                fontSize: "18px",
                textTransform: "none",
              }}
              color="secondary"
              variant="contained"
              onClick={handleNext}
              disabled={activeStep === 3 && !outputTexts.length}
            >
              {activeStep < 3
                ? activeStep === 2
                  ? t("SMAsistant.generate")
                  : t("SMAsistant.next")
                : t("SMAsistant.addPhoto")}
            </Button>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
