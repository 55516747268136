import * as React from "react";
import "./InstagramPost.css";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import InstagramPostContent from "./InstagramPostContent";
import FacebookForward from "../../../../assets/facebook_forward_arrow.png";
import FacebookThumb from "../../../../assets/facebook_thumbs_up_like.png";

export default function InstagramPost({
  postText,
  setEditedText,
  generateImages,
  image,
  imageExtension,
  fromEditVariants,
  t,
  socialMediaTarget,
  regenerate,
}) {
  const [text, setText] = React.useState("");
  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    setText(postText);

    window.addEventListener("scroll", (e) => handlePopoverClose());
  }, []);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const onSave = () => {
    setEditedText(text);
    setEdit(!edit);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent | Event) => {
    setSnackOpen(false);
  };

  const handleDownloadImage = () => {
    const href = `data:${imageExtension};base64,${image}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `image.${imageExtension}`;
    downloadLink.click();
  };

  const handleCopyToClipboard = () => {
    setSnackOpen(true);
    navigator.clipboard.writeText(text);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const MyParagraph = ({ children, ...props }) => (
    <div
      style={{ fontSize: "14px", fontWeight: "normal", whiteSpace: "pre-line" }}
      {...props}
    >
      {children}
    </div>
  );

  return (
    <>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="container container-highlight"
      >
        {generateImages && socialMediaTarget !== "instagram" ? (
          <React.StrictMode>
            <InstagramPostContent
              regenerate={regenerate}
              t={t}
              edit={edit}
              text={text}
              setEdit={setEdit}
              onSave={onSave}
              MyParagraph={MyParagraph}
              fromEditVariants={fromEditVariants}
              handlePopoverClose={handlePopoverClose}
              handleCopyToClipboard={handleCopyToClipboard}
              handleDownloadImage={handleDownloadImage}
              anchorEl={anchorEl}
              open={open}
              handleChange={handleChange}
              generateImages={generateImages}
            />
          </React.StrictMode>
        ) : (
          ""
        )}
        <div className="post-data">
          {generateImages && image ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100%", height: "350px", marginBottom: 9 }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={`data:${imageExtension};base64,${image}`}
                  alt=""
                />
              </div>
            </Box>
          ) : (
            ""
          )}
          {!generateImages ? (
            <InstagramPostContent
              regenerate={regenerate}
              t={t}
              edit={edit}
              text={text}
              setEdit={setEdit}
              onSave={onSave}
              MyParagraph={MyParagraph}
              fromEditVariants={fromEditVariants}
              handlePopoverClose={handlePopoverClose}
              handleCopyToClipboard={handleCopyToClipboard}
              handleDownloadImage={handleDownloadImage}
              anchorEl={anchorEl}
              open={open}
              handleChange={handleChange}
              generateImages={generateImages}
            />
          ) : (
            ""
          )}
          {generateImages && socialMediaTarget === "instagram" ? (
            <>
              <InstagramPostContent
                regenerate={regenerate}
                t={t}
                edit={edit}
                text={text}
                setEdit={setEdit}
                onSave={onSave}
                MyParagraph={MyParagraph}
                fromEditVariants={fromEditVariants}
                handlePopoverClose={handlePopoverClose}
                handleCopyToClipboard={handleCopyToClipboard}
                handleDownloadImage={handleDownloadImage}
                anchorEl={anchorEl}
                open={open}
                handleChange={handleChange}
                generateImages={generateImages}
              />
            </>
          ) : (
            ""
          )}
        </div>
        {generateImages ? (
          <div className="metadata">
            {socialMediaTarget === "instagram" ? (
              <div className="icon-container">
                <div>
                  <svg
                    className="show likes"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                  </svg>
                </div>
                <div className="comments">
                  <svg
                    className="show"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                    />
                  </svg>
                </div>
                <div className="share">
                  <svg
                    className="show rotate"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                    />
                  </svg>
                </div>
                <div className="save">
                  <svg
                    className="show"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <div className="social-icons-fb">
                <div className="icon-fb">
                  <img
                    src={FacebookThumb}
                    alt="Like"
                    className="icon-image-fb"
                  />
                </div>
                <div className="icon-fb">
                  <svg
                    className="show"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                    />
                  </svg>
                </div>
                <div className="icon-fb">
                  <img
                    src={FacebookForward}
                    alt="Share"
                    className="icon-image-fb"
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          autoHideDuration={1000}
          message={t("SMAsistant.InstagramPost.copyMessage")}
          onClose={handleClose}
        />
      </Box>
    </>
  );
}
