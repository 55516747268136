const {
  REACT_APP_API_URL,
  REACT_APP_GOOGLE_REDIRECT_URL,
  REACT_APP_AUTH_TOKEN_TEST_FEATURE,
} = process.env;

const baseURL = REACT_APP_API_URL;
const redirectGoogleURL = REACT_APP_GOOGLE_REDIRECT_URL;
const demoToken = REACT_APP_AUTH_TOKEN_TEST_FEATURE;

export { baseURL, redirectGoogleURL, demoToken };
