import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Alert,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { IoCloudUploadOutline } from "react-icons/io5";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useSelector } from "react-redux";
import { theme } from "../theme";
import { ConfigState } from "../http/store/reducers/config";
import uuid from "react-uuid";
import arrowUP from "../assets/dropzone_arrowup.svg";

const baseStyle = {
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 10,
  borderColor: theme.palette.figma.primary.yellow.third,
  borderStyle: "dashed",
  backgroundColor: "#272729",
  fontWeight: "600",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const lightTheme = {
  backgroundColor: "#fff3da",
  color: "#130021",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function DropZone({ form, setForm, title, mid, cta, limit }) {
  const config = useSelector((state: { config: ConfigState }) => state.config);
  const lessThanLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [openAlert, setOpenAlert] = useState(false);

  async function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  }

  function getFiles(files: File[]): Promise<string[]> {
    if (!files.length) {
      return Promise.all([]);
    }
    return Promise.all<string>(files.map(getBase64));
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      getFiles(acceptedFiles).then((imagesBase64: string[]) => {
        // Check if the number of current images + new ones exceeds 5
        const currentImagesCount = form.images.length;
        const newImages = acceptedFiles.filter((file: File) =>
          ["image/jpeg", "image/jpg", "image/png"].includes(file.type)
        );

        if (currentImagesCount + newImages.length > 5) {
          setOpenAlert(true); // Show alert
          return;
        }

        setForm({
          ...form,
          images: [
            ...form.images,
            ...acceptedFiles.map((file, index) => ({
              base64: imagesBase64[index],
              name: file.name,
            })),
          ],
          imagesExtensions: [
            ...form.imagesExtensions,
            ...newImages.map((file: File) => file.type.split("/")[1]),
          ],
        });
      });
    },
    [form.images, form.imagesExtensions, setForm]
  );

  const removeFile = (file: File) => () => {
    const newImages = form.images.filter((f) => f !== file);
    const newExtensions = form.imagesExtensions.filter(
      (_, index) => form.images.indexOf(file) !== index
    );
    setForm({
      ...form,
      images: newImages,
      imagesExtensions: newExtensions,
    });
  };

  const isLightTheme = config.theme === "light";
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    }, // Accept only JPEG, JPG, and PNG files
  });

  const acceptedFileItems = form.images.map((file: any, i) => (
    <Box key={uuid()}>
      <List>
        <ListItem disablePadding>
          <ListItemIcon>
            <InsertPhotoIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            sx={[
              lessThanLG && {
                maxWidth: "150px",
              },
            ]}
            primary={file.name}
          />
          <Button onClick={removeFile(file)}>
            <DeleteIcon />
          </Button>
        </ListItem>
      </List>
    </Box>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isLightTheme ? lightTheme : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isLightTheme, isDragReject, isDragAccept]
  );
  const currentImagesCount = form.images.length;
  const imageCountText = `${currentImagesCount}/5`;

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          {lessThanLG ? (
            ""
          ) : (
            <>
              {/* Add the arrow icon here */}
              <img
                src={arrowUP}
                alt="Arrow Up"
                style={{
                  width: "56px",
                  marginRight: "8px",
                  marginLeft: "auto",
                }}
              />
              <p style={{ margin: "0 10px", fontWeight: "300" }}>{title}</p>
              <p style={{ margin: "0 10px", fontWeight: "300" }}>
                {imageCountText}
              </p>
              <div
                style={{
                  width: "35px",
                  height: "1px",
                  backgroundColor: "#ccc",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></div>
              <p
                style={{
                  margin: "0 10px",
                  fontWeight: "300",
                }}
              >
                {mid}
              </p>
              <div
                style={{
                  width: "35px",
                  height: "1px",
                  backgroundColor: "#ccc",
                  marginLeft: "10px",
                  marginRight: "25px",
                }}
              ></div>
            </>
          )}
          <Button
            sx={{
              padding: "10px",
              marginRight: "auto",
              height: "50px",
              flex: lessThanLG ? "auto" : "",
              minWidth: lessThanLG ? "270px" : undefined,
            }}
            color="secondary"
            variant="contained"
            startIcon={<IoCloudUploadOutline />}
          >
            {cta}
          </Button>
        </div>
      </div>
      <>{acceptedFileItems}</>
      {openAlert ? (
        <Alert severity="warning" onClose={() => setOpenAlert(false)}>
          {limit}
        </Alert>
      ) : (
        ""
      )}
    </>
  );
}
