import * as React from "react";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  RadialLinearScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale
);
ChartJS.defaults.font.family = "Lexend";
ChartJS.defaults.plugins.tooltip.enabled = false;
export default function RadarChart({ outputTexts, generateImages, t }) {
  const getTotalScore = (scoreGroups: { group: string; score: number }[]) => {
    const groupsSum = scoreGroups.reduce(
      (accumulator, group) => accumulator + group.score,
      0
    );

    return (groupsSum / scoreGroups.length).toFixed(2);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            family: "Lexend",
          },
        },
      },
    },
  };
  const translationsGroups = {
    action: t("SMAsistant.ScoreMapping.action"),
    content_brief_alignment: t(
      "SMAsistant.ScoreMapping.content_brief_alignment"
    ),
    content_quality: t("SMAsistant.ScoreMapping.content_quality"),
    target_group: t("SMAsistant.ScoreMapping.target_group"),
    technical_aspects: t("SMAsistant.ScoreMapping.technical_aspects"),
    image_score: t("SMAsistant.ScoreMapping.image_score"),
  };
  return (
    <Box sx={{ height: "40vh" }}>
      <Line
        options={options}
        data={{
          labels: !outputTexts
            ? []
            : outputTexts[0].score?.scorePerGroup?.map(
                (g) => translationsGroups[g.group]
              ),
          datasets: [
            {
              label: !outputTexts
                ? ""
                : `${generateImages ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[0].score.scorePerGroup)}` : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[0].score.totalScore}`} / 100`,
              data: !outputTexts
                ? []
                : outputTexts[0].score?.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#dedede",
              borderColor: "#dedede",
              pointBackgroundColor: "#FFD785",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#FFD785",
            },

            {
              label: !outputTexts
                ? ""
                : `${generateImages ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[1].score.scorePerGroup)}` : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[1].score.totalScore}`} / 100`,
              data: !outputTexts
                ? []
                : outputTexts[1]?.score.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#ffc373",
              borderColor: "#ffc373",
              pointBackgroundColor: "#FFD785",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#FFD785",
            },

            {
              label: !outputTexts
                ? ""
                : `${generateImages ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[2].score.scorePerGroup)}` : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[2].score.totalScore}`} / 100`,
              data: !outputTexts
                ? []
                : outputTexts[2]?.score.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#ca7345",
              borderColor: "#ca7345",
              pointBackgroundColor: "#FFD785",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#FFD785",
            },
          ],
        }}
      />
    </Box>
  );
}
