import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PostScore from "./PostScore";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import { Box, DialogContent, useMediaQuery } from "@mui/material";
import { theme } from "../../../../theme";

export interface DialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export default function EditPostVariantsDialog({
  outputTexts,
  setEditedText,
  isScoreLoading,
  generateImages,
  onClose,
  selectedValue,
  open,
  t,
  socialMediaTarget
}) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const [activeCurrentSlide, setActiveCurrentSlide] = useState<number>(0);
  const handleClose = () => {
    onClose(selectedValue);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const setActivePost = (currentSlide: number) => {
    setActiveCurrentSlide(currentSlide);
  };

  return (
    <Dialog
      sx={[
        !lessThanXL && {
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",

              maxWidth: "450px",
            },
          },
        },
      ]}
      scroll={"body"}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle> {t("SMAsistant.Step5.variantEdit")}</DialogTitle>
      <DialogContent>
        <Carousel
          responsive={responsive}
          autoPlay={false}
          swipeable={true}
          draggable={false}
          showDots={true}
          infinite={true}
          partialVisible={false}
          dotListClass="custom-dot-list-style"
          containerClass="carousel-container"
          itemClass="p-3"
          afterChange={(previousSlide, active) => {
            setActivePost(active.currentSlide);
          }}
          className="edit-fifth-step-carousel"
        >
          {outputTexts
            .sort((a, b) => {
              return b.score.totalScore - a.score.totalScore;
            })
            .map((outputTextWithScore, index) => {
              return (
                <Box key={index}>
                  <PostScore
                    key={outputTextWithScore}
                    outputTextWithScore={outputTextWithScore}
                    activeCurrentSlide={activeCurrentSlide}
                    index={index}
                    setEditedText={setEditedText}
                    isScoreLoading={isScoreLoading}
                    generateImages={generateImages}
                    t={t}
                    fromEditVariants={true}
                    socialMediaTarget={socialMediaTarget}
                    regenerate={null}
                  />
                </Box>
              );
            })}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
}
