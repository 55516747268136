import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import Markdown from "markdown-to-jsx";
import CoalaAppBar from "../../components/CoalaAppBar.tsx";

const PrivacyPolicyCard = ({ t }) => {
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CoalaAppBar mobileOpen={null} t={t} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column", // Stack the privacy text above the card
          alignItems: "center",
          maxWidth: "lg",
        }}
      >
        {/* Privacy Policy text */}
        <Typography
          variant="h4"
          component="div"
          sx={{
            marginTop: "180px",
            marginBottom: "40px", // Big margin between policy text and card

            fontSize: "32px",
            background: (theme) => theme.palette.gradient.toTop,
            fontWeight: "600",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {t("privacyPolicy.title")}
        </Typography>
        <Card
          sx={{
            backgroundColor: "#ffffff", // Card color different from the background
            boxShadow: 3,
            margin: "40px", // Increased margin around the card
            width: "95%", // Card takes almost all horizontal space
            textAlign: "center", // Center align text inside the card
          }}
        >
          <CardContent>
            <Typography variant="body1" mt={3} sx={{ fontWeight: "bold" }}>
              {t("privacyPolicy.title")}
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              sx={{ lineHeight: 1.6, textAlign: "justify" }}
            >
              <Markdown>
                {t("privacyPolicy.privacyPolicyText.content")}
              </Markdown>
            </Typography>

            <Typography
              variant="body2"
              mt={3}
              sx={{ lineHeight: 1.6, textAlign: "justify" }}
            >
              {t("privacyPolicy.questionsText")}
            </Typography>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                gap: "20px", // Spacing between buttons
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "black",
                  background: (theme) => theme.palette.gradient.toTop,
                  fontSize: "11px", // Smaller font size
                  padding: "10px 20px", // Adjust button padding
                  width: "150px",
                }}
                onClick={() => navigate("/dashboard/home")} // Redirect to dashboard
              >
                {t("privacyPolicy.agree")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PrivacyPolicyCard;
