import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Card,
  CardContent,
  Tab,
  Tabs,
  ListItem,
  Container,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../theme.ts";
import PostGenerationImage from "../../assets/features_section_generate.png";
import PostAnalysisImage from "../../assets/features_section_analysis.png";
import StrategyImage from "../../assets/features_section_strategy.png";

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

function FeaturesTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function FeatureSection({ t }) {
  const [value, setValue] = React.useState(0);

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      id="features-section"
      sx={{
        background:
          "radial-gradient(circle at bottom left, rgba(255, 197, 77, 0.4), transparent 30%), radial-gradient(circle at top right, rgba(237, 112, 105, 0.4), transparent 30%)",
        padding: "75px 0 75px",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            sx={{
              margin: "auto",
              maxWidth: { xs: 350, sm: 680, md: 950 },
            }}
          >
            <Tabs
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: (theme) =>
                    theme.palette.figma.primary.yellow.third,
                },
              }}
              sx={{
                display: isXS ? undefined : "flex",
                justifyContent: isXS ? undefined : "center",
                "& .MuiTabs-flexContainer": {
                  justifyContent: isXS ? undefined : "center",
                },
                "& .MuiTab-root": { marginRight: 1, marginLeft: 1 },
              }} // Added marginRight to increase space between tabs
            >
              <Tab
                sx={{ fontWeight: "bold" }}
                label={t("layout.featuresSection.tab1")}
                {...TabProps(0)}
              />
              <Tab
                sx={{ fontWeight: "bold" }}
                label={t("layout.featuresSection.tab2")}
                {...TabProps(1)}
              />
              <Tab
                sx={{ fontWeight: "bold" }}
                label={t("layout.featuresSection.tab3")}
                {...TabProps(2)}
              />
            </Tabs>
          </Grid>
          <FeaturesTabPanel value={value} index={0}>
            <Grid my={7} container spacing={2}>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    textAlign: "left",
                  }}
                  variant="h4"
                  component="div"
                >
                  {t("layout.featuresSection.tab1Subtitle")}
                </Typography>
                <Typography
                  sx={{ textAlign: "justify", my: 2, mr: isXS ? 0 : 10, mt: 4 }}
                  component="div"
                >
                  {t("layout.featuresSection.tab1Text")}
                </Typography>
              </Grid>

              <Grid item md={6}>
                <Card
                  variant="outlined"
                  sx={{
                    minWidth: 275,
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <img alt="" src={PostGenerationImage} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </FeaturesTabPanel>
          <FeaturesTabPanel value={value} index={1}>
            <Grid my={7} container spacing={2}>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    textAlign: "left",
                  }}
                  variant="h4"
                  component="div"
                >
                  {t("layout.featuresSection.tab2Subtitle")}
                </Typography>
                <Typography
                  sx={{ textAlign: "justify", my: 2, mr: isXS ? 0 : 10, mt: 4 }}
                  component="div"
                >
                  {t("layout.featuresSection.tab2Text1")}
                </Typography>
                <ListItem
                  sx={{
                    mt: -1,
                    mb: 0,
                    fontWeight: "bold",
                    display: "list-item",
                  }}
                >
                  {t("layout.featuresSection.tab2Text2")}
                </ListItem>
                <ListItem
                  sx={{
                    mt: 0,
                    mb: 0,
                    fontWeight: "bold",
                    display: "list-item",
                  }}
                >
                  {t("layout.featuresSection.tab2Text3")}
                </ListItem>
                <ListItem
                  sx={{
                    mt: 0,
                    mb: 0,
                    fontWeight: "bold",
                    display: "list-item",
                  }}
                >
                  {t("layout.featuresSection.tab2Text4")}
                </ListItem>
                <Typography
                  sx={{ textAlign: "justify", my: 2, mr: 2, mt: 1 }}
                  component="div"
                >
                  {t("layout.featuresSection.tab2Text5")}
                </Typography>
              </Grid>

              <Grid item md={6}>
                <Card
                  variant="outlined"
                  sx={{
                    minWidth: 275,
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <img alt="" src={StrategyImage} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </FeaturesTabPanel>
          <FeaturesTabPanel value={value} index={2}>
            <Grid my={7} container spacing={2}>
              <Grid item md={6}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    textAlign: "left",
                  }}
                  variant="h4"
                  component="div"
                >
                  {t("layout.featuresSection.tab3Subtitle")}
                </Typography>
                <Typography
                  sx={{ textAlign: "justify", my: 2, mr: isXS ? 0 : 10, mt: 4 }}
                  component="div"
                >
                  {t("layout.featuresSection.tab3Text")}
                </Typography>
              </Grid>

              <Grid item md={6}>
                <Card
                  variant="outlined"
                  sx={{
                    minWidth: 275,
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <img alt="" src={PostAnalysisImage} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </FeaturesTabPanel>
        </Grid>
      </Container>
    </Box>
  );
}
