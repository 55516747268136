import i18n from "i18next";
import { initReactI18next } from "react-i18next"; // Updated import
import translationPL from "./translations/translation.pl.json";
import translationEN from "./translations/translation.eng.json";
import { getLang } from "../http/local-storage/config/LangProvider";

const language = getLang() || navigator.language.split("-")[0];
const resources = {
  pl: {
    translation: translationPL,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
