import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import PanelImage from "../../assets/maininfo_panel.png";
import ScoreImage from "../../assets/maininfo_score.png";
import { theme } from "../../theme.ts";
import ReactGA from "react-ga4";

export default function MainInfo({ t }) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid id="home" mt={14} container spacing={1} padding={1}>
        <Grid item md={7}>
          <Typography
            sx={{
              background: (theme) => theme.palette.gradient.toTop,
              fontWeight: "400",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize: isXS ? "80px" : undefined,
            }}
            variant={isXS ? "h2" : "h1"} // h1 when not xs, h2 when xs
            component="div"
          >
            {t("layout.mainInfo.title")}
          </Typography>

          <Typography
            sx={{
              fontWeight: "600",
            }}
            variant="h4"
            component="div"
          >
            {t("layout.mainInfo.subtitle")}
          </Typography>
          <Typography mt={4} mr={3} component="div">
            {t("layout.mainInfo.text")}
          </Typography>
        </Grid>
        <Grid
          item
          md={5}
          mt={3}
          sx={{
            borderRadius: "1%",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              position: "relative",
              width: "fit-content",
              backgroundColor: "transparent",
            }}
          >
            <Box
              component="img"
              src={PanelImage}
              alt="Dashboard Image"
              sx={{
                display: "block",
                border: "none",
                boxShadow: "0px 60px 100px 30px rgba(255, 197, 77, 0.25)",
                backgroundColor: "rgba(255, 197, 77, 0.25)",
                borderRadius: "20px",
              }}
            />

            <Box
              component="img"
              src={ScoreImage}
              alt="Score Image"
              sx={{
                position: "absolute",
                bottom: "-95px",
                right: "-10px",
                maxHeight: "290px",
                maxWidth: "175px",
                width: "40%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid mt={14} container spacing={1} justifyContent="center">
        <Grid item>
          <Link
            to="dashboard/home"
            style={{ textDecorationColor: "transparent" }}
          >
            <Button
              sx={{
                background: (theme) => theme.palette.gradient.mainGradient,
                color: "black",
                mb: 4,
                px: 4,
                fontSize: "20px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Tiny box shadow
              }}
              onClick={() => {
                ReactGA.event({
                  category: "user_engagement",
                  action: "click",
                  label: "landing_page_cta_btn",
                });
              }}
              variant="contained"
            >
              {t("layout.mainInfo.cta")}
            </Button>
          </Link>
        </Grid>

        {/* <Grid item md={1.5} sx={{ textAlign: "left" }}>
          <Typography
            sx={{ display: "contents", fontWeight: "600", marginTop: "0" }}
            mt={2}
            variant="h4"
            component="div"
          >
            +1,300
          </Typography>
        </Grid>

        <Grid item md={3} sx={{ textAlign: "left" }}>
          <Typography
            sx={{ display: "contents", fontWeight: "600", marginTop: "0" }}
            mt={2}
            variant="h6"
            component="div"
          >
            {t("layout.mainInfo.userNum")}
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
