import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Container,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import coalaLogo from "../assets/coala_logo.svg";
import coalaLogoGradient from "../assets/coala_logo_gradient.svg";
import { theme } from "../theme.ts";
import { Link as ScrollLink } from "react-scroll";
import uuid from "react-uuid";
import ReactGA from "react-ga4";

export default function CoalaAppBar({ mobileOpen, t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation().pathname;
  const validRoutes = ["/", "/privacy-policy", "/terms-of-use"];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDrawerToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLoginBtn = () => {
    ReactGA.event({
      category: "user_engagement",
      action: "click",
      label: "navbar_login_btn",
    });
    setAnchorEl(null);
  };

  const menuItems = t("layout.navbar.options", { returnObjects: true });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          bgcolor: !location.includes("dashboard")
            ? "white"
            : isMobile
              ? theme.palette.figma.primary.yellow.fourth
              : "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={0}
        position="fixed"
      >
        <Toolbar>
          {validRoutes.includes(location) ? (
            <Container
              disableGutters
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              {/* Logo and Title */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link
                  to="/"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={coalaLogo}
                    alt="Coala Logo"
                    style={{
                      width: "40px",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: "500",
                      color: "black",
                    }}
                    variant="h6"
                  >
                    Coala.AI
                  </Typography>
                </Link>
              </Box>

              {/* Navigation Links */}
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    gap: lessThanXL ? "70px" : "120px",
                    alignItems: "center",
                  }}
                >
                  {menuItems.map((item) => (
                    <ScrollLink
                      key={uuid()}
                      to={item.to}
                      smooth={true}
                      duration={500}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#262438",
                      }}
                    >
                      <Typography sx={{ fontWeight: 300 }}>
                        {item.label}
                      </Typography>
                    </ScrollLink>
                  ))}
                </Box>
              )}

              {/* Login Button or Menu Icon */}
              {!isMobile ? (
                <Link to="/dashboard/home" style={{ textDecoration: "none" }}>
                  <Button
                    sx={{
                      background: theme.palette.gradient.mainGradient,
                      padding: "5px 20px",
                      color: "black",
                    }}
                    variant="contained"
                    onClick={() => {
                      ReactGA.event({
                        category: "user_engagement",
                        action: "click",
                        label: "navbar_login_btn",
                      });
                    }}
                  >
                    {t("layout.navbar.loginBtn")}
                  </Button>
                </Link>
              ) : (
                <>
                  <IconButton
                    aria-label="open drawer"
                    edge="end"
                    color="primary"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleCloseLoginBtn}>
                      <Link
                        to="/dashboard/home"
                        style={{ textDecoration: "none", color: "#262438" }}
                      >
                        {t("layout.navbar.loginBtn")}
                      </Link>
                    </MenuItem>
                    {location === "/" &&
                      menuItems.map((item) => (
                        <MenuItem key={uuid()} onClick={handleClose}>
                          <ScrollLink
                            to={item.to}
                            smooth={true}
                            duration={500}
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "#262438",
                            }}
                          >
                            {item.label}
                          </ScrollLink>
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
            </Container>
          ) : (
            <Container
              disableGutters
              sx={{
                marginLeft: "0",
                marginRight: "0",
                minWidth: "100%",
                display: "flex",
              }}
            >
              <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "30px",
                    background: (theme) =>
                      isMobile ? "black" : theme.palette.gradient.toTop,
                    fontWeight: "600",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  variant="h1"
                  component="div"
                >
                  <Link to="/" style={{ textDecorationColor: "transparent" }}>
                    <img
                      src={isMobile ? coalaLogo : coalaLogoGradient}
                      alt="Coala Logo"
                      style={{
                        width: "40px",
                        marginRight: "10px",
                      }}
                    />

                    <span>Coala.AI</span>
                  </Link>
                </Typography>
              </Box>
              {isMobile && location.includes("dashboard") && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  color="primary"
                  onClick={mobileOpen}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Container>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
