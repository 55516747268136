import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import uuid from "react-uuid";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../../theme.ts";

const filter = createFilterOptions<{
  id: number;
  name: string;
}>();

export default function AutocompleteInfo({
  postPurpose,
  purposes,
  form,
  setForm,
  setPurposes,
  t,
  color,
}) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Autocomplete
      clearIcon={null}
      getOptionDisabled={(option) =>
        !!postPurpose.find((element: string) => element === option)
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={uuid()}>
            {option?.name}
          </li>
        );
      }}
      multiple
      value={postPurpose}
      onChange={(e, newValue: { id: number; name: string }[]) => {
        const last = newValue[newValue.length - 1];
        // Only add to purposes if `last` exists and is not already in purposes
        if (last && !purposes.find((c) => c?.id === last?.id)) {
          setPurposes([...purposes, last]);
        }
        setForm({
          ...form,
          postPurpose: newValue
        });
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        const isExisting = options.some(
          (option) => inputValue === option?.name
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            id: purposes.length,
            name: inputValue,
          });
        }

        return filtered;
      }}
      id="category-filter"
      options={purposes}
      getOptionLabel={(option) => (option ? option.name : "")}
      renderInput={(params) => (
        <TextField
          multiline
          rows={isXS ? 2 : 1}
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            fontSize: "14px",
            maxWidth: { xs: 350, sm: 680, md: "100%" },
            border: `solid 1px  ${color}`,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "transparent", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // Border color when focused
              },
            },
          }}
          required
          {...params}
          placeholder={
            postPurpose.length
              ? undefined
              : t("SMAsistant.Step1.purposePlaceholder")
          }
        />
      )}
    />
  );
}
