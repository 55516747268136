import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals.js";

import { StyledEngineProvider } from "@mui/material/styles";
import App from "./App.tsx";
import { configureStore } from "@reduxjs/toolkit";
import reducer from "./http/store/reducers/index.js";
import { Provider } from "react-redux";
import "./i18n/i18n.ts";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({ reducer });

root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <App />
    </Provider>
  </StyledEngineProvider>
);

reportWebVitals();
