import axios from "axios";
import { baseURL } from "./api";
import { useDispatch } from "react-redux";
import {
  getAuthorizedUser,
  setAuthorizedUser,
} from "../local-storage/auth/AuthProvider";
import { refreshToken } from "../store/reducers/auth";
import { AuthorizedUser } from "../local-storage/auth/models/authorizedUser";
import axiosRetry from "axios-retry";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
  params: {},
});

axiosRetry(axiosInstance, {
  retries: 2,
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // time interval between retries
  },
  retryCondition: (error) => {
    return true;
  },
});

let refreshingPromise = null;

const useAxiosInterceptor = (): any => {
  const dispatch = useDispatch<any>();

  axiosInstance.interceptors.request.use(async (config) => {
    const user = getAuthorizedUser() as AuthorizedUser;

    const expiration = new Date(user.authorizedUser.expirationDate);
    const now = new Date();

    try {
      if (!refreshingPromise && user) {
        if (now > expiration) {
          refreshingPromise = await dispatch(
            refreshToken(user.authorizedUser.refreshToken)
          );
          const { payload } = refreshingPromise;

          setAuthorizedUser({
            ...payload,
            authorizedUser: {
              ...payload.authorizedUser,
              expirationDate: new Date(
                Date.now() + +payload.authorizedUser.tokenExpiresIn * 1000
              ).toISOString(),
            },
          });
          const newConfig = {
            ...config,
            data: {
              ...config.data,
              user_token: {
                idToken: payload.authorizedUser.token,
              },
            },
          };
          refreshingPromise = null;
          return newConfig;
        }
      }
    } catch (error) {
      // do nothing
    } finally {
      refreshingPromise = null;
    }

    return config;
  });

  return null;
};

export default useAxiosInterceptor;
