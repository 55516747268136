import React, { useState } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { changeCurrentLang } from "../http/store/reducers/config";
import { setLang } from "../http/local-storage/config/LangProvider";
import { useDispatch } from "react-redux";

export default function LanguageSwitcher({ t }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<any>();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value;
    dispatch(changeCurrentLang(selectedLanguage)).then(() => {
      i18n.changeLanguage(selectedLanguage);
      setLang(selectedLanguage);
    });
  };

  return (
    <Box sx={{ mx: 1 }}>
      <FormControl fullWidth>
        <Box sx={{ mb: 1, color: "black", fontWeight: 600 }}>
          {t("lang.langTitle")}
        </Box>
        <Select
          value={i18n.language}
          onChange={handleChange}
          sx={{
            borderColor: "black",
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FFD785",
            },
          }}
        >
          <MenuItem value="pl">
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/pl.png`}
              srcSet={`https://flagcdn.com/w40/pl.png 2x`}
              alt="Polish flag"
              style={{ marginRight: 8 }}
            />
            Polski
          </MenuItem>
          <MenuItem value="en">
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/gb.png`}
              srcSet={`https://flagcdn.com/w40/gb.png 2x`}
              alt="British flag"
              style={{ marginRight: 8 }}
            />
            English
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
