import * as React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Layout from "./containers/Layout.tsx";
import ResponsiveDrawer from "./containers/Dashboard.tsx";
import PrivacyPolicy from "./components/legal/PrivacyPolicy.tsx";
import TermsOfUse from "./components/legal/TermsOfUse.tsx";
import SocialMediaAssystent from "./containers/dashboard-containers/SocialMediaAssystent.tsx";
import { darkTheme, theme } from "./theme.ts";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInterceptor from "./http/config/axiosInstance.ts";
import { ConfigState } from "./http/store/reducers/config.ts";
import { useTranslation } from "react-i18next"; // Replaced with useTranslation hook
import Profile from "./containers/dashboard-containers/Profile.tsx";
import { verifyUserToken } from "./http/store/reducers/auth.ts";
import {
  getAuthorizedUser,
  logoutAuthorizedUser,
} from "./http/local-storage/auth/AuthProvider.ts";
import { AuthorizedUser } from "./http/local-storage/auth/models/authorizedUser.ts";

function App() {
  const { t } = useTranslation(); // Extract the translation function
  useAxiosInterceptor();
  const dispatch = useDispatch<any>();
  const user = getAuthorizedUser() as AuthorizedUser;

  React.useEffect(() => {
    if (user?.authorizedUser) {
      dispatch(verifyUserToken(user.authorizedUser.token)).then(
        ({ payload }) => {
          if (!payload) {
            logoutAuthorizedUser();
          }
        }
      );
    }
  }, []);
  const config = useSelector((state: { config: ConfigState }) => state.config);

  const dashboardTheme = config.theme === "light" ? theme : darkTheme;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ThemeProvider theme={theme}>
              <Layout t={t} />
            </ThemeProvider>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />}></Route>

        <Route
          path="privacy-policy"
          element={
            <ThemeProvider theme={theme}>
              <PrivacyPolicy t={t} />
            </ThemeProvider>
          }
        />
        <Route
          path="terms-of-use"
          element={
            <ThemeProvider theme={theme}>
              <TermsOfUse t={t} />
            </ThemeProvider>
          }
        />

        <Route
          path="dashboard/*"
          element={
            <ThemeProvider theme={dashboardTheme}>
              <ResponsiveDrawer window={window} t={t} />
            </ThemeProvider>
          }
        >
          <Route path=":profile" element={<Profile t={t} />} />
          <Route path=":assystent" element={<SocialMediaAssystent t={t} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
