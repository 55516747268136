import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import InstagramPost from "./InstagramPost";
import CircularProgress from "@mui/material/CircularProgress";

export default function PostScore({
  outputTextWithScore,
  activeCurrentSlide,
  index,
  setEditedText,
  isScoreLoading,
  generateImages,
  t,
  fromEditVariants,
  socialMediaTarget,
  regenerate,
}) {
  const getTotalScore = (scoreGroups: { group: string; score: number }[]) => {
    if (!scoreGroups?.length) {
      return 0;
    }
    const groupsSum = scoreGroups.reduce(
      (accumulator: number, group) => accumulator + group.score,
      0
    );

    return (groupsSum / scoreGroups.length).toFixed(2);
  };
  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        {isScoreLoading && index === activeCurrentSlide ? (
          <CircularProgress color="secondary" />
        ) : (
          <Box
            sx={[
              {
                borderRadius: "12px",
                padding: "5px",
              },
              fromEditVariants && {
                background: (theme) => theme.palette.secondary.main,
              },
              !fromEditVariants &&
                index === 0 && {
                  background: (theme) => theme.palette.gradient.silver,
                },
              !fromEditVariants &&
                index === 1 && {
                  background: (theme) => theme.palette.gradient.gold,
                },
              !fromEditVariants &&
                index === 2 && {
                  background: (theme) => theme.palette.gradient.brown,
                },
            ]}
          >
            <Typography align="center">
              {generateImages
                ? t("SMAsistant.PostScore.postScore")
                : t("SMAsistant.PostScore.contentScore")}
              {` ${generateImages ? getTotalScore(outputTextWithScore?.score?.scorePerGroup) : outputTextWithScore?.score?.totalScore} / 100`}
            </Typography>
          </Box>
        )}
      </Box>
      <InstagramPost
        postText={outputTextWithScore?.postText}
        image={outputTextWithScore?.image}
        imageExtension={outputTextWithScore?.imageExtension}
        setEditedText={setEditedText}
        generateImages={generateImages}
        fromEditVariants={fromEditVariants}
        t={t}
        socialMediaTarget={socialMediaTarget}
        regenerate={regenerate}
      />
    </Box>
  );
}
