import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../config/api";
import { getAuthorizedUser } from "../../local-storage/auth/AuthProvider";
import { AuthorizedUser } from "../../local-storage/auth/models/authorizedUser";
import {
  UserLogin,
  GoogleRedirect,
  GoogleLogin,
} from "../../local-storage/auth/models/userLogin";

export interface AuthState {
  currentUser: AuthorizedUser;
  isLoading: boolean;
}

const initialState = {
  currentUser: undefined,
  isLoading: false,
} satisfies AuthState as AuthState;

export const register = createAsyncThunk(
  "async/register",
  async (userData: UserLogin, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/sign_up_with_email_and_password`,
        userData
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(`error: ${err.request.response}`);
    }
  }
);

export const login = createAsyncThunk(
  "async/login",
  async (userData: UserLogin, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/sign_in_with_email_and_password`,
        userData
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(`error: ${err.request.response}`);
    }
  }
);

export const loginGoogleURL = createAsyncThunk(
  "async/loginGoogleURL",
  async (redirectUrl: GoogleRedirect, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/create_google_sign_in_link`,
        redirectUrl
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(`error: ${err.request.response}`);
    }
  }
);

export const loginGoogle = createAsyncThunk(
  "async/loginGoogle",
  async (data: GoogleLogin, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/sign_in_with_google_code`,
        data
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(`error: ${err.request.response}`);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "async/getCurrentUser",
  async (data: any, thunkAPI) => {
    try {
      const response = await new Promise((resolve, reject) => {
        const user = getAuthorizedUser() as AuthorizedUser;
        if (user) {
          return resolve(user);
        } else {
          return reject({ detail: "error" });
        }
      });
      return response as AuthorizedUser;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.detaili);
    }
  }
);

export const logout = createAsyncThunk(
  "async/logout",
  async (userId: string, thunkAPI) => {
    try {
      const response = await axios.post(`${baseURL}/auth/logout`, {
        id: userId,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "async/resetPassword",
  async (email: string, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/send_reset_password_email`,
        { email }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const refreshToken = createAsyncThunk(
  "async/refreshToken",
  async (refreshToken: string, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/auth/create_new_user_token`,
        { refreshToken }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

export const verifyUserToken = createAsyncThunk(
  "async/verifyUserToken",
  async (idToken: string, thunkAPI) => {
    try {
      const response = await axios.post(`${baseURL}/auth/verify_user_token`, {
        idToken,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: null,
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = {
          ...action.payload,
          authorizedUser: {
            ...action.payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +action.payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        };
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = {
          ...action.payload,
          authorizedUser: {
            ...action.payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +action.payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        };
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginGoogleURL.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginGoogleURL.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginGoogleURL.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginGoogle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginGoogle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = {
          ...action.payload,
          authorizedUser: {
            ...action.payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +action.payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        };
      })
      .addCase(loginGoogle.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.currentUser = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = null;
      })
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = {
          ...action.payload,
          authorizedUser: {
            ...action.payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +action.payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        };
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(verifyUserToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUserToken.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export default authSlice.reducer;
