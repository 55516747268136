import * as React from "react";
import "./InstagramPost.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button } from "@mui/material";
import Markdown from "markdown-to-jsx";
import Popover from "@mui/material/Popover";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";

export default function InstagramPostContent({
  edit,
  text,
  handleChange,
  setEdit,
  onSave,
  MyParagraph,
  fromEditVariants,
  handlePopoverClose,
  handleCopyToClipboard,
  handleDownloadImage,
  anchorEl,
  open,
  generateImages,
  t,
  regenerate,
}) {
  const containerRef = React.useRef();
  return (
    <div className="post-content">
      <>
        {edit ? (
          <Box ref={containerRef}>
            <textarea
              value={text}
              style={{ width: "100%" }}
              rows={18}
              onChange={handleChange}
            />

            <Box sx={{ justifyContent: "end", display: "flex" }}>
              <Button
                onClick={() => setEdit(!edit)}
                color="primary"
                variant="contained"
                sx={{ marginRight: "5px" }}
              >
                {t("SMAsistant.InstagramPost.discard")}
              </Button>
              <Button
                onClick={() => onSave()}
                color="secondary"
                variant="contained"
              >
                {t("SMAsistant.InstagramPost.save")}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              <div>
                <Markdown
                  options={{
                    overrides: {
                      h1: { component: MyParagraph },
                      h2: { component: MyParagraph },
                      h3: { component: MyParagraph },
                      p: { component: MyParagraph },
                      span: { component: MyParagraph },
                      strong: { component: MyParagraph },
                      b: { component: MyParagraph },
                      li: { component: MyParagraph },
                      ul: { component: MyParagraph },
                    },
                  }}
                >
                  {text.replace(/\n/g, " \n  \n")}
                </Markdown>
              </div>
            </Box>

            <Popover
              container={fromEditVariants ? null : containerRef.current}
              style={{ pointerEvents: "none" }}
              id="mouse-over-popover"
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              disableAutoFocus={true}
              onClose={handlePopoverClose}
            >
              <Button
                sx={{ pointerEvents: "auto" }}
                autoFocus={false}
                color="secondary"
                variant="contained"
                onClick={() => handleCopyToClipboard()}
              >
                <ContentCopyIcon />
              </Button>

              {generateImages ? (
                <Button
                  sx={{ pointerEvents: "auto" }}
                  autoFocus={false}
                  color="secondary"
                  variant="contained"
                  onClick={() => handleDownloadImage()}
                >
                  <DownloadIcon />
                </Button>
              ) : (
                ""
              )}

              {generateImages ? (
                ""
              ) : (
                <Button
                  sx={{ pointerEvents: "auto" }}
                  autoFocus={false}
                  color="secondary"
                  variant="contained"
                  onClick={() => setEdit(true)}
                >
                  <EditIcon />
                </Button>
              )}

              {generateImages ? (
                ""
              ) : (
                <Button
                  sx={{ pointerEvents: "auto" }}
                  autoFocus={false}
                  color="secondary"
                  variant="contained"
                  onClick={() => regenerate()}
                >
                  <RestartAltIcon />
                </Button>
              )}
            </Popover>
          </>
        )}
      </>
    </div>
  );
}
