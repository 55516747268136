import React from "react";
import { Box, Typography, Grid, Container, Link } from "@mui/material";

export default function MetricsSection({ t }) {
  const benefits = [
    {
      value: t("layout.metricsSelection.card1.value"),
      label: t("layout.metricsSelection.card1.text"),
      color: "rgba(255, 197, 77, 0.7)",
      gradient:
        "linear-gradient(180deg, rgba(255,197,77,1) 0%, rgba(255,197,77,0.22) 100%)",
    },
    {
      value: t("layout.metricsSelection.card2.value"),
      label: t("layout.metricsSelection.card2.text"),
      color: "rgba(237, 112, 105, 0.7)",
      link: "https://ec.europa.eu/eurostat/web/products-eurostat-news/w/ddn-20240529-2",
      gradient:
        "linear-gradient(180deg, rgba(237,112,105,1) 0%, rgba(237,112,105,0.22) 100%)",
    },
    {
      value: t("layout.metricsSelection.card3.value"),
      label: t("layout.metricsSelection.card3.text"),
      color: "rgba(191, 215, 234, 0.7)",
      link: "https://www.revenuemarketingalliance.com/ai-in-marketing-implications-for-revenue-marketers/",
      gradient:
        "linear-gradient(180deg, rgba(191,215,234,1) 0%, rgba(191,215,234,0.22) 72%)",
    },
  ];

  return (
    <Box
      sx={{
        background: "linear-gradient(335deg, #1E1857 0%, 85%,  #975C34 100%)",
        padding: "75px 0 75px",
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          sx={{
            color: (theme) => theme.palette.figma.primary.yellow.third,
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          {t("layout.metricsSelection.title")}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          mb={8}
          sx={{ color: "#FFFFFF" }}
        >
          {t("layout.metricsSelection.subtitle")}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {benefits.map((benefit, index) => (
            <Grid item key={index} xs={12} sm={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  width: "160px",
                  height: "160px",
                  position: "relative",
                  boxShadow: `0 0 8px ${benefit.color}`,
                  margin: "0 auto",

                  // Add position relative for the pseudo-element
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    padding: "14px", // This should match your border width
                    boxSizing: "border-box",
                    background: `${benefit.gradient}`, // Gradient for border
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)", // Mask to show inner content
                    WebkitMaskComposite: "destination-out",
                    maskComposite: "exclude", // Mask compatibility for more browsers
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  {benefit.value}
                </Typography>
              </Box>
              <Box
                my={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  align="center"
                  variant="h5"
                  sx={{
                    color: "#FFFFFF",
                    maxWidth: "60%",
                  }}
                >
                  {benefit.label}
                  {benefit.link && (
                    <Link
                      href={benefit.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "1rem", // Super small text
                        color: "#FFFFFF",
                        textDecoration: "none", // No underline
                        position: "relative", // Enable positioning
                        top: "-4px", // Move the asterisk upward
                      }}
                    >
                      *
                    </Link>
                  )}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
