import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import CoalaAppBar from "../../components/CoalaAppBar.tsx";

const PrivacyPolicyCard = ({ t }) => {
  const totalTerms = 12;
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CoalaAppBar mobileOpen={null} t={t} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "lg",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{
            marginTop: "180px",
            marginBottom: "40px",

            fontSize: "32px",
            background: (theme) => theme.palette.gradient.toTop,
            fontWeight: "600",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {t("termsOfUse.title")}
        </Typography>
        <Card
          sx={{
            backgroundColor: "#ffffff",
            boxShadow: 3,
            margin: "40px",
            width: "95%",
            textAlign: "center",
          }}
        >
          <CardContent>
            <Typography
              variant="body1"
              mt={3}
              mb={3}
              sx={{ fontWeight: "bold" }}
            >
              {t("termsOfUse.termsUseTitle")}
            </Typography>
            <>
              {[...Array(totalTerms)].map((_, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  mt={3}
                  sx={{ lineHeight: 1.6, textAlign: "justify" }}
                >
                  {t(`termsOfUse.termsUseText${index + 1}`)}
                </Typography>
              ))}
            </>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                gap: "20px", // Spacing between buttons
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "black",
                  background: (theme) => theme.palette.gradient.toTop,
                  fontSize: "11px", // Smaller font size
                  padding: "10px 20px", // Adjust button padding
                  width: "150px",
                }}
                onClick={() => navigate("/dashboard/home")} // Redirect to dashboard
              >
                {t("termsOfUse.agree")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PrivacyPolicyCard;
