import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../../../theme";
import { useEffect, useState } from "react";
import uuid from "react-uuid";

export default function ScoreTable({ outputTexts, activeCurrentSlide, t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const [indexList, setIndexList] = useState<number[]>([0, 1, 2]);

  const scoreAtIndex = (index: number, groupName: string) => {
    return outputTexts[index]?.score.scorePerGroup?.find(
      (g) => g.group === groupName
    ).score;
  };

  const translationsGroups = {
    action: t("SMAsistant.ScoreMapping.action"),
    content_brief_alignment: t(
      "SMAsistant.ScoreMapping.content_brief_alignment"
    ),
    content_quality: t("SMAsistant.ScoreMapping.content_quality"),
    target_group: t("SMAsistant.ScoreMapping.target_group"),
    technical_aspects: t("SMAsistant.ScoreMapping.technical_aspects"),
    image_score: t("SMAsistant.ScoreMapping.image_score"),
  };

  useEffect(() => {
    setIndexList([0, 1, 2]);
  }, []);

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", justifyContent: "center" }}
      mt={1}
    >
      {outputTexts[0].score?.scorePerGroup?.map((group, index) => {
        return (
          <Grid
            container
            key={uuid()}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item md={12} xs={12}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Box
                    sx={{
                      borderBottom: "1px solid rgba(80,81,104,0.5)",
                      marginRight: lessThanXL ? "8px" : "16px",
                      marginLeft: lessThanXL ? "8px" : "38px",
                      flexGrow: 1,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "#1E1857",
                      fontSize: 12,
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {translationsGroups[group.group]}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Box
                    sx={{
                      borderBottom: "1px solid rgba(80,81,104,0.5)",
                      marginLeft: lessThanXL ? "8px" : "16px",
                      marginRight: lessThanXL ? "8px" : "38px",
                      flexGrow: 1,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} mt={1} mb={1}>
              <Grid container spacing={2}>
                {(lessThanXL ? [activeCurrentSlide] : indexList).map((val) => {
                  return (
                    <Grid key={uuid()} item xs={12} md={4}>
                      <Box
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={[
                            {
                              borderRadius: "12px",
                              width: "100px",
                              padding: "5px",
                              color: "#F18624",
                            },
                            scoreAtIndex(val, group.group) >= 90 && {
                              color: "#1DA412",
                            },
                            scoreAtIndex(val, group.group) <= 60 && {
                              color: "#DF2935",
                            },
                          ]}
                        >
                          <Typography
                            sx={{ fontSize: "32px", fontWeight: 500 }}
                          >
                            {scoreAtIndex(val, group.group)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
