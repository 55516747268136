import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, useMediaQuery } from "@mui/material";
import { theme } from "../../theme.ts";
import { Link } from "react-router-dom";
import dashboardLike from "../../assets/dashboard_like.svg";
import dashboardConstruction from "../../assets/dashboard_construction.svg";
import backgroundBrand from "../../assets/dashboard_background_brand.svg";
import BrandCreateBanner from "../../components/BrandCreateBaner.tsx";

export default function Home({ t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const renderCardWithLink = (toLink, title, description, icon) => (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {toLink ? (
        <Link
          style={{
            textDecoration: "none",
            height: "100%",
            width: "100%",
            display: "block",
          }}
          to={toLink}
        >
          <Card
            variant="outlined"
            sx={{
              width: "100%",
              height: "100%",
              border: `1.5px solid ${theme.palette.dashboard.rand}`, // Add border color
              borderRadius: "8px", // Add border radius for rounded corners
            }}
          >
            <CardActionArea
              sx={{
                width: "100%",
                height: "100%",
                minWidth: 275,
                minHeight: 150,
              }}
            >
              <CardContent>
                {/* Flexbox layout for aligning icon to the left and text to the right */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* Left-aligned icon with increased size and color */}
                  <Box sx={{ marginRight: "10px", minWidth: "40px" }}>
                    <img
                      src={icon}
                      alt="Icon"
                      style={{
                        width: "38px",
                        height: "38px",
                      }}
                    />
                  </Box>
                  {/* Right-aligned text content */}
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        textAlign: "left",
                      }}
                      variant="h6"
                      component="div"
                    >
                      {title}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                      component="div"
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      ) : (
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.figma.secondary.orange.second, // Orange background color
            border: `1.5px solid ${theme.palette.figma.secondary.orange.second}`, // Border color
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.8,
          }}
        >
          <CardActionArea
            sx={{
              width: "100%",
              height: "100%",
              minWidth: 275,
              minHeight: 150,
              pointerEvents: "none", // Disable clicking
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px", // Ensure inner radius matches outer card
              boxShadow: `${theme.palette.figma.secondary.orange.second} 0px 0px 12px`, // Border shadow
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {/* Center-aligned text */}
              <Typography
                sx={{
                  fontWeight: "600",
                  color: "white", // White text color
                }}
                variant="h6"
                component="div"
              >
                {t("resposiveDrawer.home.comingSoonBadge")}
              </Typography>
              {/* Center-aligned icon */}
              <Box>
                <img
                  src={dashboardConstruction}
                  alt="Icon"
                  style={{
                    width: "38px",
                    height: "38px",
                  }}
                />
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </Box>
  );

  return (
    <Grid
      container
      spacing={3}
      mt={1}
      mb={5}
      sx={{ justifyContent: lessThanXL ? "center" : "" }}
    >
      {/* we uncomment it once we can create the brand
      <Grid item xs={12} sx={{ width: "100%", marginLeft: "5px" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              width: "100%",
              border: "none",
              backgroundImage: `url(${backgroundBrand})`, // Background image
              backgroundSize: "cover", // Cover the entire card
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat", // Do not repeat the image
              borderRadius: "8px", // Add border radius for rounded corners
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item md={12} lg={8}>
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 30,
                      right: -36, // Adjusted to keep the ribbon inside the card
                      background: (theme) => theme.palette.secondary.main,
                      color: "black",
                      padding: "4px 2px", // Increase the padding to extend the length of the ribbon
                      transform: "rotate(45deg)",
                      fontSize: "10px",
                      zIndex: 1,
                      width: "170px", // Increase the width to ensure the ribbon fully wraps
                      textAlign: "center", // Center the text within the wider ribbon
                    }}
                  >
                    {t("resposiveDrawer.home.comingSoonBadge")}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: (theme) => theme.palette.primary.contrastText,
                      width: "90%",
                    }}
                    variant="h6"
                    component="div"
                  >
                    {t("resposiveDrawer.home.brandCreateTitle")}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                      width: isXS ? "100%" : "50%",
                    }}
                  >
                    {t("resposiveDrawer.home.brandCreateDesc")}
                  </Typography>
                </Grid>
                <Grid item md={12} lg={3}>
                  <CardActions>
                    <Button
                      disabled={true}
                      style={{ textTransform: "none" }}
                      sx={{
                        background: (theme) => theme.palette.secondary.main,
                        color: (theme) => theme.palette.secondary.contrastText,
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginTop: "10px",
                        width: isXS ? "300px" : "100%",
                      }}
                    >
                      {t("resposiveDrawer.home.brandCreateCTA")}
                    </Button>
                  </CardActions>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      */}

      <BrandCreateBanner t={t} isXS={isXS} />
      {/* First Section of Cards */}
      <Grid item md={12} lg={4} sx={{ width: "100%" }}>
        <Typography
          sx={{
            marginLeft: "5px",
            textAlign: "left",
            background: (theme) => theme.palette.primary.main,
            fontWeight: 400,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h5"
          component="div"
        >
          {t("resposiveDrawer.home.createTitle")}
        </Typography>
        <Grid container spacing={2} mt={isXS ? 0 : 1}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            {renderCardWithLink(
              "../assystent", // Valid link to "assystent"
              t("resposiveDrawer.home.createGeneratePosts"),
              t("resposiveDrawer.home.createGenerateDesc"),
              dashboardLike
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Second Section of Cards */}
      <Grid item md={12} lg={4} sx={{ width: "100%" }} mt={isXS ? 2 : 0}>
        <Typography
          sx={{
            marginLeft: "5px",
            textAlign: "left",
            background: (theme) => theme.palette.primary.main,
            fontWeight: 400,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h5"
          component="div"
        >
          {t("resposiveDrawer.home.analyzeTitle")}
        </Typography>
        <Grid container spacing={2} mt={isXS ? 0 : 1}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            {renderCardWithLink(
              "", // No link, displays "Coming Soon"
              t("resposiveDrawer.home.analyzeTrends"),
              t("resposiveDrawer.home.analyzeTrendsDesc"),
              ""
            )}
          </Grid>

          <Grid item xs={12} sx={{ width: "100%" }} mt={1}>
            {renderCardWithLink(
              "", // No link, displays "Coming Soon"
              t("resposiveDrawer.home.analyzeMarket"),
              t("resposiveDrawer.home.analyzeMarketDesc"),
              ""
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Third Section of Cards */}
      <Grid
        item
        md={12}
        lg={4}
        sx={{ width: "100%" }}
        mt={isXS ? 2 : 0}
        mb={isXS ? 2 : 0}
      >
        <Typography
          sx={{
            marginLeft: "5px",
            textAlign: "left",
            background: (theme) => theme.palette.primary.main,
            fontWeight: 400,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h5"
          component="div"
        >
          {t("resposiveDrawer.home.planTitle")}
        </Typography>
        <Grid container spacing={2} mt={isXS ? 0 : 1}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            {renderCardWithLink(
              "", // No link, displays "Coming Soon"
              t("resposiveDrawer.home.planPersonas"),
              t("resposiveDrawer.home.planPersonasDesc"),
              ""
            )}
          </Grid>

          <Grid item xs={12} sx={{ width: "100%" }} mt={1}>
            {renderCardWithLink(
              "", // No link, displays "Coming Soon"
              t("resposiveDrawer.home.planMI"),
              t("resposiveDrawer.home.planMIDesc"),
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
