import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../theme.ts";

export default function Testimonials({ t }) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        background:
          "linear-gradient(355deg, rgba(255, 232, 184, 1) 0%, 40%, rgba(255, 232, 184, 0) 100%)",
        padding: "75px 0 125px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          sx={{
            color: "#000",
            fontWeight: "bold",
            mt: 7,
          }}
        >
          {t("layout.testimonialsSection.title")}
        </Typography>
        <Grid container my={3} mb={4} spacing={3} justifyContent="center">
          {t("layout.testimonialsSection.reviews", { returnObjects: true }).map(
            (testimonial, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                  }}
                >
                  {(index !== 0 || isXS) && (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        borderColor: (theme) =>
                          theme.palette.figma.primary.yellow.third,
                        borderRightWidth: 2,
                        margin: "auto",
                        height: "60%",
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      padding: "20px",
                      background: "transparent",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start", // Aligns content to the top
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#000",
                        marginBottom: "20px",
                        minHeight: "150px",
                      }}
                    >
                      {testimonial.text}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "right",
                        mt: "auto", // Keeps author text at the bottom
                      }}
                    >
                      {testimonial.author}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </Box>
  );
}
